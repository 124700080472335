import React from 'react';
import {
  Logo,
  HomeIcon,
  CalendarIcon,
  ChatIcon,
  ClassIcon,
  LibraryIcon,
  MailIcon,
  ReportsIcon,
  SocialMediaIcon
} from './assets/images';
import { Box, Button, IconButton } from '@material-ui/core';
import { withTheme, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import './assets/styles/Sidebar.css';

const LIST_ITEMS = [
  { name: 'Home', icon: HomeIcon },
  { name: 'Class', icon: ClassIcon },
  { name: 'Calendar', icon: CalendarIcon },
  { name: 'Mail', icon: MailIcon },
  { name: 'Chat', icon: ChatIcon },
  { name: 'Library', icon: LibraryIcon },
  { name: 'Reports', icon: ReportsIcon },
  { name: 'Social Media', icon: SocialMediaIcon }
];

class Sidebar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.onCollapseButtonClicked = this.onCollapseButtonClicked.bind(this);

    this.state = { isCollapsed: true }
  }

  onCollapseButtonClicked() {
    this.setState((prevState) => ({
      ...prevState,
      isCollapsed: !prevState.isCollapsed
    }));
  }

  render() {
    const { theme } = this.props;

    return (
      <Box
        id="sidebar"
        component="aside"
        style={{ backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.light})` }}
        className={this.state.isCollapsed ? `__collapsed` : ''}
      >
        <img src={Logo} width={47} height={48} alt="Logo" />

        <Button className="sidebar-collapser" color="primary" data-test-id="sidebar-collapser" onClick={this.onCollapseButtonClicked}>
          <ChevronRight className="chevron-icon" />
        </Button>

        <div className="nav-menu-wrapper">
          {LIST_ITEMS.map(item => (
            <Button className="nav-menu-item" key={item.name}>
              <img src={item.icon} alt={item.name} width={31} height={31} />
              <span className="button-text">{item.name}</span>
            </Button>
          ))}
        </div>
      </Box>
    );
  }
}

interface IProps {
  theme: Theme
}

interface IState {
  isCollapsed: boolean;
}

export default withTheme(Sidebar);
