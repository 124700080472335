import React from 'react';
import { Avatar as MuiAvatar } from '@material-ui/core';
import { withTheme, Theme } from '@material-ui/core/styles';
import './assets/styles/BorderedAvatar.css';

class Avatar extends React.Component<IProps, IState> {
  render() {
    const { className = '', src, width = 48, height = 48, theme } = this.props;

    return (
      <MuiAvatar
        className={`avatar-field ${className}`}
        src={src}
        style={{
          borderColor: theme.palette.primary.main,
          width,
          height
        }}
      />
    );
  }
}

interface IProps {
  width?: number | string;
  height?: number | string;
  src: string;
  className?: string;
  theme: Theme
}

interface IState {}

export default withTheme(Avatar);
