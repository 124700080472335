import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { FileIcon, GoalsIcon, ImagesIcon, VideoIcon, ProfilePlaceholderImage } from './assets/images';
import './assets/styles/ProfileSummaryView.css';

class ProfileSummaryView extends React.Component<IProps, IState> {
  render() {
    const {
      profilePicture,
      name,
      title,
      isVerifiedUser,
      className = '',
      totalImagesCount,
      totalFilesCount,
      totalVideosCount,
      totalGoalsCount,
      size = 'wide',
      profileType = 'notMyProfile'
    } = this.props;

    return (
      <div
        className={`profile-summary-view-wrapper ${className} ${
          size === 'wide' ? '__wide' : '__narrow'
        }`}
      >
        <div className="profile-picture-area">
          <div className="image-wrapper">
            <img
              src={profilePicture || ProfilePlaceholderImage}
              width={112}
              height={112}
              alt={profilePicture ? 'profile-picture' : 'profile-placeholder-image'}
            />
          </div>
        </div>

        <div className="profile-title">
          <Typography variant="h5" className="fullname" align="center">
            {name}
          </Typography>

          <div className="title-line">
            <Typography component="span" align="center" className="title">
              {title}
            </Typography>

            {isVerifiedUser && <CheckCircle className="verified-user-icon" />}
          </div>
        </div>

        <div className="user-summary-count-wrapper">
          <Paper square className="summary-item">
            <img src={ImagesIcon} alt="images" />
            <Typography component="span" className="count">
              {totalImagesCount}
            </Typography>

            {size === 'wide' && (
              <Typography component="span" className="item-name">
                Images
              </Typography>
            )}
          </Paper>

          <Paper square className="summary-item">
            <img src={FileIcon} alt="images" />
            <Typography component="span" className="count">
              {totalFilesCount}
            </Typography>

            {size === 'wide' && (
              <Typography component="span" className="item-name">
                Files
              </Typography>
            )}
          </Paper>

          <Paper square className="summary-item">
            <img src={VideoIcon} alt="images" />

            <Typography component="span" className="count">
              {totalVideosCount}
            </Typography>

            {size === 'wide' && (
              <Typography component="span" className="item-name">
                Videos
              </Typography>
            )}
          </Paper>

          <Paper square className="summary-item">
            <img src={GoalsIcon} alt="images" />
            <Typography component="span" className="count">
              {totalGoalsCount}
            </Typography>

            {size === 'wide' && (
              <Typography component="span" className="item-name">
                Goals
              </Typography>
            )}
          </Paper>
        </div>
      </div>
    );
  }
}

export interface IProps { 
  id?:number,
  className?: string;
  profilePicture: string;
  name: string;
  title: string;
  isVerifiedUser: boolean;
  totalImagesCount: number;
  totalFilesCount: number;
  totalVideosCount: number;
  totalGoalsCount: number;
  size?: 'wide' | 'narrow';
  profileType: "myProfile" | "visitingTeacher" | "visitingStudent" | "unset";
}

interface IState {}

export default ProfileSummaryView;
