import React,{ Component } from "react";
import {
    Grid,
} from "@material-ui/core";
import {Download} from './assets/images';
import { IFile } from "./SinglePost";
import { ACTIVE_LANDING_PAGE_BOXED_VIEW } from "./types";

type MyProps = {
    file?:IFile,
    boxedView?:ACTIVE_LANDING_PAGE_BOXED_VIEW
}
type MyState = {}

class SinglePostContentFiles extends Component<MyProps,MyState>{
    render(){
        const {file,boxedView} = this.props
        if(file){
            return(
                <a className="download-wrapper" download href="">
                    <Grid className="file-box" container direction="row" wrap="nowrap" item alignItems="center" justifyContent="space-between">
                        <Grid container item alignItems="center">
                            <img className="file-icon" src={file.image} alt=""/>
                            <span className={`${boxedView !== 'NONE' && "file-text"}`}>{file.text}</span>
                        </Grid>
                        <img className="download-icon" src={Download} alt=""/>
                    </Grid>
                </a>
            )
        }
        else return <></>
    }
}

export default SinglePostContentFiles