import React, {Component} from 'react';
import SinglePost, { IPost } from './SinglePost';
import { ACTIVE_LANDING_PAGE_TAB } from "./types";

type MyProps = {
    posts: IPost[];
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    handleHidePost:(id:number)=>void;
}
type MyState = {}

class VideosTab extends Component<MyProps,MyState> {
    render(){
        if(this.props.activeTab === 'VIDEOS') {
            return(
                <>
                    {this.props.posts.map((item: IPost) => (
                      <SinglePost
                        hidePost={this.props.handleHidePost}
                        activeTab={this.props.activeTab}
                        createPost
                        key={item.id}
                        item={item}
                      />
                    ))}
                </>
            )
        }
        else return <></>
    }
}

export default VideosTab
