import React from 'react';
import { Container } from '@material-ui/core';
import { createTheme, ThemeProvider, Theme } from '@material-ui/core/styles';
import { COLORS } from '../utilities/Colors';

class SignContainer extends React.Component<React.PropsWithChildren<IProps>, IState> {
    constructor(props: IProps) {
        super(props);

        const theme = createTheme({
            palette: {
                primary: {
                    light: COLORS.TEACHER.primary.light,
                    main: COLORS.TEACHER.primary.main,
                },
                divider: COLORS.TEACHER.divider
            },
            typography: {
                allVariants: {
                    fontFamily: "'Open Sans', sans-serif"
                }
            }
        });

        this.state = { theme }
    }

    render() {
        return (
            <ThemeProvider theme={this.state.theme}>
                <Container>
                    <>
                        {this.props.children}
                    </>
                </Container>
            </ThemeProvider>
        )
    }
}

interface IProps {}

interface IState {
    theme: Theme
}

export default SignContainer;