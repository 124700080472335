import React from "react";

// Customizable Area Start
import moment from "moment";
import {
  CssBaseline,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  Avatar as MuiAvatar,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {
  ArrowBackIosOutlined,
  FavoriteOutlined,
} from "@material-ui/icons";
import {Form, Formik, FormikHelpers, FormikValues} from "formik";
import {EmojiClickData} from 'emoji-picker-react';
import GenericCard from "../../../components/src/GenericCard.web";
import Avatar from "../../../components/src/BorderedAvatar.web";
import BorderColoredTextfield from "../../../components/src/BorderColoredTextfield";
import MainContainer from "../../../components/src/containers/MainContainer.web";
import EmojiPicker from "../../../components/src/EmojiPicker";
import "../../../components/src/assets/styles/normalize.css";
import "./main.css";

interface IValues {
  comment:string
}
// Customizable Area End

import UserStatusController, {
  Props,
  configJSON,
} from "./UserStatusController";

export default class UserStatus extends UserStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    console.log(configJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  handleAddComment = (values:IValues,actions:FormikHelpers<IValues>) => {
    console.log(values)
  }
  handleClickEmoji = (emojiData: EmojiClickData,values:FormikValues,setFieldValue:any) => {
    setFieldValue("comment",values.comment + emojiData.emoji)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <CssBaseline />

        <MainContainer
          className="all-stories-page-main-container"
          usePageHeader
          navigation={this.props.navigation}
          pageTitle="Stories"
        >
          <Grid container id="all-stories-page" alignItems="stretch">
            <Grid item className="all-stories-sider">
              <GenericCard title="All Stories">
                {this.state.usersOfStories.map((user, idx) => (
                  <Button
                    key={idx}
                    variant="text"
                    color={
                      this.state.activeUser?.id === user.id
                        ? "primary"
                        : "default"
                    }
                    className={`all-stories-story-item ${
                      this.state.activeUser?.id === user.id ? "__active" : ""
                    }`}
                    onClick={() => this.setActiveUser(user, idx)}
                  >
                    <Avatar
                      src={user.profilePicture}
                      width={54}
                      height={54}
                    />

                    <div className="texts">
                      <Typography component="strong">
                        {user.fullname}
                      </Typography>
                      <Typography component="small">
                        {moment
                          .duration(moment(new Date()).diff(user.createdAt))
                          .asHours()
                          .toFixed(0)}
                        h
                      </Typography>
                    </div>
                  </Button>
                ))}
              </GenericCard>
            </Grid>

            <Grid item className="all-stories-content">
              {this.state.activeUser && (
                <GenericCard className="active-stories-content-wrapper">
                  <IconButton
                    className="back-button"
                    disabled={this.state.activeStoryIndex === 0}
                    onClick={this.prevStory}
                  >
                    <ArrowBackIosOutlined />
                  </IconButton>

                  <Grid
                    container
                    direction="column"
                    className="story-viewer-container"
                  >
                    <Grid item className="story-viewer-wrapper">
                      <GenericCard className="story-viewer">
                        <div className="story-wrapper">
                          <div className="story-head">
                            <div className="story-navs">
                              {this.state.activeUser?.stories.map(
                                (img, idx) => (
                                  <span
                                    key={idx}
                                    className={`story-bar ${
                                      idx === this.state.activeStoryIndex
                                        ? "__active"
                                        : ""
                                    }`}
                                  />
                                )
                              )}
                            </div>

                            <div className="story-owner">
                              <MuiAvatar
                                src={this.state.activeUser.profilePicture}
                                className="avatar"
                              />
                              <Typography component="h6">
                                {this.state.activeUser.fullname}
                              </Typography>
                            </div>
                          </div>

                          <div
                            className="story-content"
                            style={{
                              backgroundImage: `url(${
                                this.state.activeUser.stories[
                                  this.state.activeStoryIndex
                                ]?.source
                              })`,
                            }}
                          />

                          <IconButton
                            className="favorite-btn"
                            color="primary"
                          >
                            <FavoriteOutlined />
                          </IconButton>
                        </div>
                      </GenericCard>
                    </Grid>

                    <Grid item className="reply-wrapper">
                      <Formik
                              data-test-id="formik"
                              initialValues={{comment:""}}
                              onSubmit={(values, actions) => {
                                  this.handleAddComment(values,actions);
                                  actions.setSubmitting(false);
                                  actions.resetForm()
                              }}
                          >
                            {({ values, setFieldValue }) => (
                              <>
                                <Form className="emoji-textarea-container" translate="yes">
                                  <BorderColoredTextfield
                                    className={`textarea`}
                                    name="comment"
                                    placeholder="Reply..."
                                    icon={
                                      <EmojiPicker
                                        handleClickEmoji={this.handleClickEmoji}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                      />
                                    }
                                  />
                                </Form>
                              </>
                            )}
                          </Formik>
                    </Grid>
                  </Grid>

                  <IconButton
                    className="next-button"
                    disabled={
                      this.state.activeStoryIndex ===
                      this.state.activeUser.stories.length - 1
                    }
                    onClick={this.nextStory}
                  >
                    <ArrowBackIosOutlined className="next-icon" />
                  </IconButton>
                </GenericCard>
              )}

              {!this.state.activeUser && (
                <GenericCard>Select a user</GenericCard>
              )}
            </Grid>
          </Grid>
        </MainContainer>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
