import React, {Component} from 'react';
import {
    IconButton,
    Menu
} from "@material-ui/core";
import './assets/styles/createPostBox.css'
import {AttachFile, ProfilePicture} from './assets/images';
import AddFileDialog from "./AddFileDialog";
import {Field, Form, Formik, FormikHelpers, FormikValues} from "formik";
import * as Yup from 'yup'
import {IComment} from "./CommentsDialog";
import {EmojiClickData} from 'emoji-picker-react';
import EmojiPicker from './EmojiPicker';

interface IValues {
    comment:string
}

type MyProps = {
    handleAddComment?: (comment:IComment) => void | undefined
}
type MyState = {
    isOpen:boolean,
    files:any,
    showFiles:boolean,
}

class CreatePostBox extends Component<MyProps,MyState> {
    constructor(props:any){
        super(props);
        this.state = {
            isOpen:false,
            files:[],
            showFiles:false,
        }
    }

    handleCloseAddFileDialog = () => {
        this.setState({isOpen:false})
    }

    handleOpenAddFileDialog = () => {
        this.setState({isOpen:true})
    }

    handleAddComment = (values:IValues,actions:FormikHelpers<IValues>) => {
        console.log(values)
        if(this.props.handleAddComment){
            this?.props?.handleAddComment({id:Math.random(),image:ProfilePicture,name:"Albert Smith",comment:values.comment})
        }
    }

    onDrop = (files:any) => {
        this.setState({files})
    };

    handleDeleteFile = (id:number) => {
        this.setState((state)=>({files:state.files.filter((item:any,index:number)=>index!==id)}))
    }

    handleAddFiles = () => {
        this.setState({showFiles:true,isOpen:false})
    }

    handleClickEmoji = (emojiData: EmojiClickData,values:FormikValues,setFieldValue:any) => {
        setFieldValue("comment",values.comment + emojiData.emoji)
    }

    render(){
        const { isOpen, files } = this.state

        return (
            <>
                <div className="create-post-box">
                    <img src={ProfilePicture} alt=""/>
                    <div className="create-post-box-field">
                        <Formik
                            data-test-id="formik"
                            initialValues={{comment:""}}
                            onSubmit={(values, actions) => {
                                this.handleAddComment(values,actions);
                                actions.setSubmitting(false);
                                actions.resetForm()
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <>
                                    <Form translate="yes">
                                        <Field name="comment" placeholder="Write your comment..." type="text"/>
                                    </Form>
                                    <div className="create-post-box-buttons">
                                        <IconButton onClick={this.handleOpenAddFileDialog} disableRipple data-test-id="openFile" className="create-post-box-button">
                                            <img src={AttachFile} alt=""/>
                                        </IconButton>
                                        {/* <IconButton onClick={this.handleOpenEmojiDialog} disableRipple className="create-post-box-button">
                                            <img src={SentimentSatisfied} alt=""/>
                                        </IconButton>
                                        <Menu
                                            id="emoji"
                                            anchorEl={this.state.anchorElEmoji}
                                            keepMounted
                                            open={Boolean(this.state.anchorElEmoji)}
                                            onClose={this.handleCloseEmojiDialog}
                                            className="filter-menu"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 0,
                                                horizontal: 230,
                                            }}
                                        >
                                            <Picker onEmojiClick={(x)=>this.handleClickEmoji(x,values,setFieldValue)} />
                                        </Menu> */}
                                        <EmojiPicker
                                            handleClickEmoji={this.handleClickEmoji}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
                <AddFileDialog handleAddFiles={this.handleAddFiles} onDrop={this.onDrop} handleDeleteFile={this.handleDeleteFile} files={files} isOpen={isOpen} handleCloseModal={this.handleCloseAddFileDialog}/>
            </>
        );
    }
}

export default CreatePostBox;
