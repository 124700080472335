import React,{Component} from "react";
import {Button, FormControl, InputBase, InputLabel, MenuItem, Paper, Select, withStyles} from "@material-ui/core";
import "./assets/styles/addQuestionBox.css"
import TargetGroupSelectBox from "./TargetGroupSelectbox.web";

const classes = {
    root: {
        borderRadius:'8px',
        boxShadow:'0 0 15px 0 rgba(0, 0, 0, 0.1)',
        padding:'18px 20px',
        width:'100%'
    },
};

type MyProps = {
    classes:any
}

type MyState = {
    topic:string
}

class AddQuestionBox extends Component<MyProps,MyState>{
    constructor(props:any){
        super(props)
        this.state = {
            topic:"Choose"
        }
    }

    handleTopicChange = () => {

    }

    render(){
        const {classes} = this.props
        const {topic} = this.state
        return(
            <div className="add-question-box-wrapper">
                <Paper className={classes.root}>
                    <div className="add-question-box-filters">
                        <span className="add-question-box-title">Discussion Topic</span>
                        <FormControl variant="outlined" className="add-question-select-box">
                            <InputLabel>Choose</InputLabel>
                            <Select
                                className="_add-question-select-box"
                                // value={topic}
                                onChange={this.handleTopicChange}
                                name="topic"
                            >
                                <MenuItem value="Computed Science">Computer Science</MenuItem>
                                <MenuItem value="Physics">Physics</MenuItem>
                            </Select>
                        </FormControl>
                        <InputBase
                            className="add-question-box-title-input"
                            placeholder="Title of the question"
                        />
                    </div>
                    <InputBase
                        multiline
                        rows={2}
                        className="add-question-box-question-input"
                        placeholder="Add your question"
                    />
                    <div className="add-question-box-footer">
                        <TargetGroupSelectBox size="normal" />
                        <div className="add-question-box-buttons">
                            <Button className="cancel-button">Cancel</Button>
                            <Button className="add-button" variant="contained" color="primary">Add</Button>
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }
}

export default withStyles(classes)(AddQuestionBox)
