import React from 'react';
import { Box } from '@material-ui/core';
import { createTheme, ThemeProvider, Theme } from '@material-ui/core/styles';
import Sidebar from '../Sidebar.web';
import Header from '../Header.web';
import { COLORS } from '../utilities/Colors';

import '../assets/styles/MainContainer.css';

class MainContainer extends React.Component<React.PropsWithChildren<IProps>, IState> {
  constructor(props: IProps) {
    super(props);

    const theme = createTheme({
      palette: {
        primary: {
          light: COLORS.TEACHER.primary.light,
          main: COLORS.TEACHER.primary.main,
        },
        divider: COLORS.TEACHER.divider
      },
      typography: {
        allVariants: {
          fontFamily: "'Open Sans', sans-serif"
        }
      }
    });

    this.state = { theme }
  }
  render() {
    const { isFullWidth = false, className = '', usePageHeader = false, pageTitle, navigation } = this.props;

    return (
      <ThemeProvider theme={this.state.theme}>
        <Box id="main-container" className={`${className} ${isFullWidth ? '__fullwidth' : ''}`}>
          <Sidebar />

          <Box className="content-area">
            <Box className="radius-background" bgcolor="primary.main" />
            <Box className="radius-background-2" bgcolor="primary.light" />
            <div className="content-area-wrapper">
              <Header
                navigation={navigation}
                usePageHeader={usePageHeader}
                pageTitle={pageTitle}
              />

              {this.props.children}
            </div>
          </Box>
        </Box>
      </ThemeProvider>
    )
  }
}

interface IProps {
  isFullWidth?: boolean;
  className?: string;
  usePageHeader?: boolean;
  pageTitle?:string;
  navigation?:any;
}
interface IState {
  theme: Theme
}

export default MainContainer;