import React from 'react';
import {Field} from "formik";
import { withTheme, Theme } from "@material-ui/core/styles";
import "./assets/styles/BorderColoredTextfield.css"


const BorderColoredTextfield: React.FC<IProps> = ({
    theme,
    className = '',
    name,
    placeholder,
    icon,
}) => {

    return (
      <div className="border-colored-text-field-wrapper">
        <Field
          name={name}
          placeholder={placeholder}
          style={{ borderColor: theme.palette.primary.main }}
          className={`${className}`}
        />
        <div style={{ color: theme.palette.primary.main }} className="border-colored-text-field-icon">
          {icon}
        </div>
      </div>
    )
}

interface IProps {
    theme: Theme,
    className:string,
    name:string,
    placeholder:string,
    icon:any,
}

export default withTheme(BorderColoredTextfield); 