import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import React from 'react';
import CreateContentWidget from './CreateContentWidget.web';
import { CalendarIcon2 } from "./assets/images";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import './assets/styles/CreateGoalWidget.css';

class CreateGoalWidget extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.state = {
      startDate: new Date(),
      endDate: moment().add(1, 'days').toDate()
    }
  }

  handleDateChange = (date: Date) => {
    this.setState({
      startDate: date
    })
  };

  handleEndDateChange = (date: Date) => {
    this.setState({
      endDate: date
    })
  };

  render() {
    return (
      <CreateContentWidget
        title="Create Goal"
        actionButtonTitle="Add Goal"
        inputPlaceholder="What's New, Albert?"
        dialogTitle="Add to Goal"
        isMultipleImageSelection
      >
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <div id="create-goal-widget-wrapper">
            <div>
              <label>Start Date</label>

              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="mm/DD/yyyy"
                margin="normal"
                animateYearScrolling
                inputVariant="outlined"
                id="date-picker-inline"
                disablePast
                value={this.state.startDate}
                onChange={this.handleDateChange}
                labelFunc={(str: string) => {
                  return moment(str).format('LL');
                }}
                keyboardIcon={<img src={CalendarIcon2} />}
              />
            </div>

            <div>
              <label>End Date</label>

              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="mm/DD/yyyy"
                margin="normal"
                inputVariant="outlined"
                disablePast
                id="date-picker-inline"
                minDate={moment(this.state.startDate).add(1, 'days').toDate()}
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
                labelFunc={(str: string) => {
                  return moment(str).format('LL')
                }}
                keyboardIcon={<img src={CalendarIcon2} />}
              />
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </CreateContentWidget>
    )
  }
}

interface IProps {}

interface IState {
  startDate: Date;
  endDate: Date;
}

export default CreateGoalWidget;