import React from 'react';
import { Divider, Paper, Typography } from '@material-ui/core';
import './assets/styles/GenericCard.css';

class GenericCard extends React.Component<
  React.PropsWithChildren<IProps>,
  IState
> {
  render() {
    const {
      title,
      titleAction,
      children,
      className = '',
      id = ''
    } = this.props;

    return (
      <Paper id={id} className={`generic-card ${className}`} square>
        {title && (
          <>
            <div className="generic-card-title">
              <Typography className="generic-card-subtitle">{title}</Typography>

              {titleAction}
            </div>

            <Divider className="card-divider" />
          </>
        )}

        <div className="generic-card-content">{children}</div>
      </Paper>
    );
  }
}

interface IProps {
  title?: string;
  titleAction?: JSX.Element | null;
  className?: string;
  id?: string;
}

interface IState {}

export default GenericCard;
