import React,{ Component } from "react";
import {
    Button,
} from "@material-ui/core";
import {ArrowDownIcon} from './assets/images';
import { IComment } from "./CommentsDialog";
import CommentBox from "./CommentBox";

type MyProps = {
    isCommentsOpen:boolean,
    comments:IComment[] | undefined,
    displayedComments:number,
    handleLoadMoreComments:()=>void,
}
type MyState = {}

class SinglePostContentComment extends Component<MyProps,MyState>{
    render(){
        const {isCommentsOpen,comments,displayedComments,handleLoadMoreComments} = this.props
            return(
                <>
                    {
                        isCommentsOpen && comments && comments?.length > 0 &&
                        <div className="comments">
                            {
                                comments?.map((item:IComment,index:number)=>{
                                    if(index < displayedComments){
                                        return(
                                            <CommentBox item={item}/>
                                        )
                                    }
                                })
                            }
                        </div>
                    }
                    {
                        comments && comments?.length > displayedComments && isCommentsOpen &&
                        <Button onClick={handleLoadMoreComments} startIcon={<img src={ArrowDownIcon} alt=""/>} className="load-more-button" variant="text" >Load more comments</Button>
                    }
                </>
                
            )
        }
    }

export default SinglePostContentComment