import React from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowBackIosOutlined, DeleteOutline } from '@material-ui/icons';
import DialogWrapper from './DialogWrapper';
import './assets/styles/ImageCarouselDialog.css';

class CreateContentImageCarousel extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.goPrev = this.goPrev.bind(this);
        this.goNext = this.goNext.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onImageRemove = this.onImageRemove.bind(this);

        this.state = { activeImageIndex: -1, modalVisibility: false }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(event: KeyboardEvent) {
        let isPressedAllowedKeys = false;

        switch (event.keyCode) {
            case 39: // Right
                this.goNext();
                isPressedAllowedKeys = true;
                break;
            case 37: // Left
                this.goPrev();
                isPressedAllowedKeys = true;
                break;
        }

        if (isPressedAllowedKeys) {
            event.preventDefault();
        }
    }

    openDialog(initialImageIndex: number) {
        this.setState({
            modalVisibility: true,
            activeImageIndex: initialImageIndex
        })
    }

    isDialogOpened() {
        return this.state.modalVisibility;
    }

    goPrev() {
        this.setState((prevState) => {
            if (prevState.activeImageIndex === 0) {
                return prevState;
            }

            return { ...prevState, activeImageIndex: prevState.activeImageIndex - 1 }
        });
    }

    goNext() {
        this.setState((prevState) => {
            if (prevState.activeImageIndex === this.props.images.length - 1) {
                return prevState;
            }

            return { ...prevState, activeImageIndex: prevState.activeImageIndex + 1 }
        });
    }

    onImageRemove() {
        let index = this.state.activeImageIndex;
        let removedIndex = index;

        if (index === this.props.images.length - 1) {
            index -= 1;
        }

        this.setState({
            activeImageIndex: index,
        }, () => {
            this.props.onImageRemove && this.props.onImageRemove(removedIndex);
        });
    }

    handleCloseModal() {
        this.setState({
            modalVisibility: false
        });
    }

    render() {
        const { activeImageIndex, modalVisibility } = this.state;
        const { images, deletable = false } = this.props;

        return (
            <DialogWrapper
                handleCloseModal={this.handleCloseModal}
                isOpen={modalVisibility}
                id="image-carousel-dialog"
            >
                {
                    deletable &&
                    <IconButton
                        className="remove-image-button"
                        onClick={this.onImageRemove}
                    >
                        <DeleteOutline />
                    </IconButton>
                }
                <div className="carousel-wrapper">
                    <IconButton
                        className="back-button"
                        disabled={activeImageIndex === 0}
                        onClick={this.goPrev}
                        color="primary"
                    >
                        <ArrowBackIosOutlined />
                    </IconButton>

                    <div className="images-wrapper">
                        <div
                            className="image-wrapper"
                            style={{ backgroundImage: `url(${this.props.images[this.state.activeImageIndex]})` }}
                        />
                    </div>

                    <IconButton
                        className="next-button"
                        disabled={activeImageIndex === images.length - 1}
                        onClick={this.goNext}
                        color="primary"
                    >
                        <ArrowBackIosOutlined className="next-icon" />
                    </IconButton>
                </div>
            </DialogWrapper>
        )
    }
}

interface IProps {
    images: string[];
    onImageRemove?: (idx: number) => void;
    deletable?: boolean;
}

interface IState {
    activeImageIndex: number;
    modalVisibility: boolean;
}

export default CreateContentImageCarousel;