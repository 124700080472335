import React, {Component} from 'react';
import "./assets/styles/sendInChatDialog.css"
import DialogWrapper from "./DialogWrapper";
import SinglePostContents from "./SinglePostContents";
import {IPost} from "./SinglePost";
import {Button, IconButton} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import {withTheme, Theme} from "@material-ui/core/styles";
import {ProfilePicture,SearchIcon} from "./assets/images";
import { configJSON } from '../../blocks/landingpage/src/LandingPageController';
import { getHexAsRgba } from './utilities/Utils';
import BorderedAvatarWeb from './BorderedAvatar.web';

type MyProps = {
    item:IPost,
    isOpen:boolean,
    handleCloseModal: () => void;
    theme: Theme;
}
type MyState = {}



class SendInChatDialog extends Component<MyProps, MyState> {
    constructor(props:any) {
        super(props);
    }
    render() {
        const {isOpen,handleCloseModal,item, theme} = this.props;
        return (
            <DialogWrapper isOpen={isOpen} handleCloseModal={handleCloseModal}>
                <div className="send-in-chat-body">
                    <div className="send-in-chat-header">
                        <h3 className="send-in-chat-title">Send In Chat</h3>
                        <div className="post-information">
                            <SinglePostContents activeTab="TIMELINE" isUserInformationActive item={item}/>
                        </div>
                        <div className="send-in-chat-post-box">
                            <img src={ProfilePicture} alt=""/>
                            <input placeholder="Say something about this..." type="text"/>
                        </div>
                    </div>
                    <div className="send-in-chat-divider"></div>
                    <div className="send-in-chat-recent">
                        <div
                            className="search-bar"
                            style={{
                                borderColor: getHexAsRgba(theme.palette.primary.main, 0.4)
                            }}
                        >
                            <input
                                className="search-input"
                                placeholder="Search by students, name…"
                            />
                            <IconButton className="search-button" color="primary" aria-label="search">
                                <SearchOutlined className="search-icon" />
                            </IconButton>
                        </div>
                        <h3 className="recent-title">Recent</h3>
                        <div className="recent-activity">
                            {
                                configJSON.recents.map((item:any)=>(
                                    <div className="recent-activity-item" key={item.id}>
                                        <div className="recent-information">
                                            <BorderedAvatarWeb src={item.image} width={54} height={54} />
                                            <h3 className="recent-title">{item.name}</h3>
                                        </div>
                                        <Button
                                            className="send-recent-button"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Send
                                        </Button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        )
    }
}

export default withTheme(SendInChatDialog);
