import React from 'react';
import { Button, Typography } from '@material-ui/core';
import GenericCard from './GenericCard.web';
import './assets/styles/newsWidget.css';
import { NewsImage } from './assets/images';

class NewsWidget extends React.Component<IProps, IState> {
    render() {
        const { data, title } = this.props;

        return (
            <GenericCard
                id="news-widget"
                title={title}
            >
                {data.length === 0 && (
                    <div className="empty-news-wrapper">
                        {
                            title === 'News' &&
                            <img
                                src={NewsImage}
                                width="auto"
                                height={86}
                                alt="empty-news"
                            />
                        }
                        <Typography component="span">No {title} Data</Typography>
                    </div>
                )}

                {data.length > 0 && (
                    <>
                        {data.map((d,index) => (
                            <div className="news-item" key={d.id}>
                                <div className="news-item-header">
                                    <Typography component="span" className="news-title">
                                        {d.title}
                                    </Typography>
                                    <Typography
                                        component="small"
                                        className="news-created_at"
                                        variant="caption"
                                    >
                                        {d.createdAt}
                                    </Typography>
                                </div>
                                <Typography component="span" className="news-content">
                                    {d.content}
                                </Typography>
                                {
                                    index !== data.length-1 &&
                                    <div className="news-divider"></div>
                                }
                            </div>
                        ))}

                        <Button
                            //onClick={()=>this.props.navigation.navigate("News")}
                            variant="contained"
                            color="primary"
                            className="view-all-button"
                            fullWidth
                        >
                            Visit Website
                        </Button>
                    </>
                )}
            </GenericCard>
        );
    }
}

export interface INewsData {
    id: string | number;
    title: string;
    content: string;
    createdAt: string;
}

interface IProps {
    data: INewsData[];
    title: string;
    navigation?:any
}

interface IState {}

export default NewsWidget;
