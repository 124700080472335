import React from 'react';
import { Box, MenuItem, Select } from '@material-ui/core';
import { PublicOutlined, LockOutlined } from '@material-ui/icons';
import './assets/styles/TargetGroupSelectbox.css';

class TargetGroupSelectBox extends React.Component<IProps> {
  render() {
    const { size = 'normal' } = this.props;

    return (
      <div id="target-group-wrapper">
        <Select
          className={`target-group-selectbox __${size}`}
          displayEmpty
          defaultValue="Public"
          autoWidth
          variant="filled"
          disableUnderline
          color="primary"
          renderValue={(value) => (
            <Box className="selectbox-wrapper">
              {value === 'Private' ? (
                <LockOutlined className="selectbox-icon" />
              ): (
                <PublicOutlined className="selectbox-icon" />
              )}

              {value}
            </Box>
          )}
        >
          <MenuItem value="Public" className="target-group-menu-item">
            <Box component="div" className="menu-item-wrapper">
              <PublicOutlined className="menu-icon" />
              <div className="texts">
                <b>Public</b>
                <small>Anyone can see</small>
              </div>
            </Box>
          </MenuItem>

          <MenuItem value="Private" className="target-group-menu-item">
            <Box component="div" className="menu-item-wrapper">
              <LockOutlined className="menu-icon" />
              <div className="texts">
                <b>Private</b>
                <small>Only me</small>
              </div>
            </Box>
          </MenuItem>
        </Select>
      </div>
    )
  }
}

interface IProps {
  size?: 'normal' | 'small'
}

export default TargetGroupSelectBox;