Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const ASSETS = require('./assets');

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.GetMethod = "GET";
exports.profileWidgetApiEndPoint = "profile";
exports.profileApiBaseUrl = "https://8215-2401-4900-1c18-510d-3371-40f5-1b34-1fe3.in.ngrok.io";
exports.contentType = "application/json"

exports.profileNotifications = [
  {
    id: Math.random(),
    profileImage: ASSETS.Man1Photo,
    text: "Andrew Smith added new photo: Lorem ipsum dolor",
    createdAt: "Today at 10:00 am"
  },
  {
    id: Math.random(),
    profileImage: ASSETS.Woman1Photo,
    text: "William John posted a new video: school lorem ipsum",
    createdAt: "Today at 11:00 am"
  },
  {
    id: Math.random(),
    profileImage: ASSETS.Man2Photo,
    text: "John Doe added 4 new photos: There lorem ipsum",
    createdAt: "Today at 12:00 am"
  },
  {
    id: Math.random(),
    profileImage: ASSETS.Man3Photo,
    text: "Emily Smith added new story: Lorem ipsum dolor",
    createdAt: "Today at 13:00 am"
  },
  {
    id: Math.random(),
    profileImage: ASSETS.Man42Photo,
    text: "Alexandra Smith added new photo: Lorem ipsum dolor",
    createdAt: "Today at 14:00 am"
  },
  {
    id: Math.random(),
    profileImage: ASSETS.Woman1Photo,
    text: "John Doe added new video: Lorem ipsum dolor sit amet",
    createdAt: "Today at 15:00 am"
  },
  {
    id: Math.random(),
    profileImage: ASSETS.Woman2Photo,
    text: "Andrew Smith added new goal: Lorem sit amet",
    createdAt: "Today at 16:00 am"
  }
];

exports.profileStudentGoals = [
  {
    fullname: "Samantha",
    profileImage: ASSETS.Woman1Photo,
    id: Math.random(),
    dueDate: "November 25, 2020",
    goal: "Get into a higher ed institution."
  },
  {
    fullname: "John Doe",
    profileImage: ASSETS.Man1Photo,
    id: Math.random(),
    dueDate: "May 05, 2022",
    goal: "Improve French"
  },
  {
    fullname: "Andrew Smith",
    profileImage: ASSETS.Man2Photo,
    id: Math.random(),
    dueDate: "November 25, 2020",
    goal: "Lorem Ipsum Certificate"
  },
  {
    fullname: "William John",
    profileImage: ASSETS.Man3Photo,
    id: Math.random(),
    dueDate: "May 05, 2022",
    goal: "Participate in Extracurricular activities"
  }
];

exports.profileContactUsers = [
  { id: Math.random(), profileImage: ASSETS.Man1Photo, title: "Andrew Smith" },
  { id: Math.random(), profileImage: ASSETS.Man3Photo, title: "William John" },
  { id: Math.random(), profileImage: ASSETS.Man2Photo, title: "John Doe" },
  { id: Math.random(), profileImage: ASSETS.Woman1Photo, title: "Emily Smith" },
  {
    id: Math.random(),
    profileImage: ASSETS.Woman2Photo,
    title: "Alexandra Smith"
  }
];

exports.profileEventItems = [
  {
    type: "BIRTHDAY",
    id: Math.random(),
    event: "Today Maria Illescas’s birthday."
  },
  {
    type: "BIRTHDAY",
    id: Math.random(),
    event: "Today Maria Illescas’s birthday."
  },
  {
    type: "REMINDER",
    id: Math.random(),
    event: "Lorem ipsum is simply dummy text of the."
  },
  {
    type: "REMINDER",
    id: Math.random(),
    event: "Lorem ipsum is simply dummy text of the."
  }
];

exports.userInfo = {
  id:1,
  name: "Albert Smith",
  title: "Teacher | Class of 2022",
  isVerifiedUser: true,
  profilePicture: ASSETS.ProfilePicture,
  totalFilesCount: 38,
  totalGoalsCount: 89,
  totalImagesCount: 154,
  totalVideosCount: 50,
  biography: `It is a long established fact that a reader
    will be distracted by the readable content
    of a page when looking at its layout. The
    point of using Lorem Ipsum is that it has a
    more-or-less normal distribution of letters.
  `
};

exports.userType = "TEACHER";

exports.profileOtherUserPictures = [
  ASSETS.Woman1Photo,
  ASSETS.Man1Photo,
  ASSETS.Man2Photo,
  ASSETS.Man3Photo,
  ASSETS.Man42Photo,
  ASSETS.Man5Photo,
  ASSETS.Woman2Photo,
  ASSETS.Woman2Photo,
  ASSETS.Woman2Photo,
  ASSETS.Man1Photo,
  ASSETS.Woman1Photo,
  ASSETS.Man42Photo,
  ASSETS.Woman1Photo,
  ASSETS.Man42Photo,
  ASSETS.Man2Photo,
  ASSETS.Man3Photo,
];

exports.tabs = [
  { id: 'TIMELINE', text: "Timeline" },
  { id: 'GOALS', text: "Goals" },
  { id: 'VIDEOS', text: "Videos" },
  { id: 'FILES', text: "Files" },
  { id: 'BADGES', text: "Badges" },
  { id: 'DISCUSSION', text: "Discussion Forum" }
];

exports.stories = [
  { id: 1, mainImage: ASSETS.StoryMain, miniImage: ASSETS.Man1Photo },
  { id: 2, mainImage: ASSETS.StoryMainTwo, miniImage: ASSETS.Man2Photo },
  { id: 3, mainImage: ASSETS.StoryMainThree, miniImage: ASSETS.Woman1Photo },
  { id: 4, mainImage: ASSETS.StoryMainFour, miniImage: ASSETS.Woman2Photo }
];

exports.posts = [
  {
    id: 1,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "30 mins ago",
    allowedTo: "Public",
    description:
      "It is a long established fact that a reader will be distracted by the readable\n" +
      "content of a page when looking at its layout. The point of using Lorem\n" +
      "Ipsum is that it has a more-or-less normal distribution of letters.",
    interactions:{
      likes:"179",
      comments:[
        {
            id:1,
            image:ASSETS.CommentOne,
            name:"Andrew Smith",
            comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        },
        {
            id:2,
            image:ASSETS.CommentTwo,
            name:"William John",
            comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        },
        {
            id:3,
            image:ASSETS.CommentThree,
            name:"Emily Smith",
            comment:"It is a long established fact that a reader will be distracted"
        },
        {
            id:4,
            image:ASSETS.CommentFour,
            name:"Alexandra",
            comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        },
        {
            id:5,
            image:ASSETS.CommentFive,
            name:"Samantha",
            comment:"Lorem Ipsum is distribution of letters."
        },
        {
            id:6,
            image:ASSETS.CommentTwo,
            name:"William John",
            comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        },
        {
            id:7,
            image:ASSETS.CommentOne,
            name:"Andrew Smith",
            comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        },
        {
            id:8,
            image:ASSETS.CommentTwo,
            name:"William John",
            comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        },
        {
            id:9,
            image:ASSETS.CommentThree,
            name:"Emily Smith",
            comment:"It is a long established fact that a reader will be distracted"
        },
        {
            id:10,
            image:ASSETS.CommentFour,
            name:"Alexandra",
            comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        },
        {
            id:11,
            image:ASSETS.CommentFive,
            name:"Samantha",
            comment:"Lorem Ipsum is distribution of letters."
        },
        {
            id:12,
            image:ASSETS.CommentTwo,
            name:"William John",
            comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        },
    ],
      shares:"3",
    },
    isFavorite: true
  },
  {
    id: 2,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "30 mins ago",
    allowedTo: "Public",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the\n" +
      "majority have suffered alteration in some form, by injected humour, or\n" +
      "randomised words which don't look even believable.",
    interactions:{
      likes:"500",
      comments:[],
      shares:"24",
    },
    isFavorite: false,
    images: [
      { id: 1, image: ASSETS.PostImage },
      { id: 2, image: ASSETS.PostImageTwo },
      { id: 3, image: ASSETS.PostImageThree },
      { id: 4, image: ASSETS.PostImageThree }
    ]
  },
  {
    id: 3,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "30 mins ago",
    allowedTo: "Public",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the\n" +
      "majority have suffered alteration in some form:",
    interactions:{
      likes:"500",
      comments:[],
      shares:"24",
    },
    isFavorite: false,
    video: {
      link: "https://www.youtube.com/watch?v=Y-OLlJUXwKUxkHAGd",
      image: ASSETS.PostVideoImage
    }
  },
  {
    id: 4,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "30 mins ago",
    allowedTo: "Public",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting\n" +
      "industry.",
    interactions:{
      likes:"179",
      comments:[],
      shares:"18",
    },
    isFavorite: false,
    file:{
      image:ASSETS.Pdf,
      text:"Recommendation Letter"
    }
  },
  {
    id: 5,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "30 mins ago",
    allowedTo: "Public",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting\n" +
      "industry.",
    interactions:{
      likes:"78",
      comments:[],
      shares:"24",
    },
    isFavorite: true,
    link: {
      text: "https://www.calcareercenter.org/Home/Content?contentID=174"
    }
  },
  {
    id: 6,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "30 mins ago",
    allowedTo: "Public",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting\n" +
      "industry.",
    isFavorite: false,
    interactions:{
      likes:"20",
      comments:[],
      shares:"37",
    },
    file:{
        image:ASSETS.Doc,
        text:"exercises_complete.docx"
    }
  }
];

exports.studentGoals = [
  {
    id: 1,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "10 mins ago",
    allowedTo: "Public",
    interactions:{
      likes:"179",
      comments:[],
      shares:"3",
    },
    goalText: "Improve my public speaking",
    dueDate: "November 25, 2020",
    isFavorite: false
  },
  {
    id: 2,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "10 mins ago",
    allowedTo: "Public",
    interactions:{
      likes:"179",
      comments:[],
      shares:"3",
    },
    goalText: "Achieved a 5 in all DP Courses",
    dueDate: "May 05, 2023",
    isFavorite: false
  },
  {
    id: 3,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "10 mins ago",
    allowedTo: "Public",
    interactions:{
      likes:"179",
      comments:[],
      shares:"3",
    },
    goalText: "Achieved a 5 in all DP Courses",
    isFavorite: true,
    goalPicture: ASSETS.Certificate
  },
  {
    id: 4,
    name: "Ganesh Aacharya",
    picture: ASSETS.PostPicture,
    postedTime: "10 mins ago",
    allowedTo: "Public",
    interactions:{
      likes:"179",
      comments:[],
      shares:"3",
    },
    goalText: "Completed UI Design course",
    isFavorite: true,
    goalPicture: ASSETS.CertificateTwo
  }
];

exports.videos = [
  {
      id:1,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"30 mins ago",
      allowedTo:"Public",
      description:"There are many variations of passages of Lorem Ipsum available, but the\n" +
          "majority have suffered alteration in some form:",
      interactions:{
          likes:"500",
          comments:[],
          shares:"24",
      },
      isFavorite:false,
      video:{
          link:"https://www.youtube.com/watch?v=Y-OLlJUXwKUxkHAGd",
          image:ASSETS.PostVideoImage,
          source:"https://www.youtube.com/embed/Y-OLlJUXwKU",
          embed:true
      }
  },
  {
      id:2,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"30 mins ago",
      allowedTo:"Public",
      description:"There are many variations of passages of Lorem Ipsum available, but the\n" +
          "majority have suffered alteration in some form:",
      interactions:{
          likes:"500",
          comments:[],
          shares:"24",
      },
      isFavorite:false,
      video:{
          image:ASSETS.PostVideoImageThree
      }
  },
  {
      id:3,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"30 mins ago",
      allowedTo:"Public",
      description:"There are many variations of passages of Lorem Ipsum available, but the\n" +
          "majority have suffered alteration in some form:",
      interactions:{
          likes:"500",
          comments:[],
          shares:"24",
      },
      isFavorite:false,
      video:{
          link:"https://vimeo.com/84198419",
          image:ASSETS.PostVideoImageThree,
          source:"https://player.vimeo.com/video/84198419?h=15124bc56c",
          embed:true
      }
  },
]

exports.files = [
  {
      id:1,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"It is a long established fact that a reader\n" +
          "will be distracted by the readable content\n" +
          "of a page when looking at its layout.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:true,
      file:{
          image:ASSETS.Doc,
          text:"exercises_complete.docx"
      }
  },
  {
      id:2,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"Lorem Ipsum is simply dummy text of\n" +
          "the printing and typesetting industry.",
      interactions:{
          likes:"124",
          comments:[
            {
                id:1,
                image:ASSETS.CommentOne,
                name:"Andrew Smith",
                comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
            },
            {
                id:2,
                image:ASSETS.CommentTwo,
                name:"William John",
                comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
            },
            {
                id:3,
                image:ASSETS.CommentThree,
                name:"Emily Smith",
                comment:"It is a long established fact that a reader will be distracted"
            },
        ],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Pdf,
          text:"exercises_complete.pdf"
      }
  },
  {
      id:3,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"There are many variations of passages\n" +
          "of Lorem Ipsum available.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Xls,
          text:"exercises_complete.xls"
      }
  },
  {
      id:4,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"There are many variations of passages\n" +
          "of Lorem Ipsum available.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Xls,
          text:"exercises_complete.xls"
      }
  },
  {
      id:5,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"It is a long established fact that a reader\n" +
          "will be distracted by the readable content\n" +
          "of a page when looking at its layout.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Doc,
          text:"exercises_complete.docx"
      }
  },
  {
      id:6,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"Lorem Ipsum is simply dummy text of\n" +
          "the printing and typesetting industry.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Doc,
          text:"exercises_complete.docx"
      }
  },
  {
      id:7,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"Lorem Ipsum is simply dummy text of\n" +
          "the printing and typesetting industry.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Pdf,
          text:"exercises_complete.pdf"
      }
  },
  {
      id:8,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"There are many variations of passages\n" +
          "of Lorem Ipsum available.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Xls,
          text:"exercises_complete.xls"
      }
  },
  {
      id:9,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"It is a long established fact that a reader\n" +
          "will be distracted by the readable content\n" +
          "of a page when looking at its layout.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Doc,
          text:"exercises_complete.docx"
      }
  },
  {
      id:10,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"It is a long established fact that a reader\n" +
          "will be distracted by the readable content\n" +
          "of a page when looking at its layout.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:true,
      file:{
          image:ASSETS.Doc,
          text:"exercises_complete.docx"
      }
  },
  {
      id:11,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"Lorem Ipsum is simply dummy text of\n" +
          "the printing and typesetting industry.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Pdf,
          text:"exercises_complete.pdf"
      }
  },
  {
      id:12,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      description:"There are many variations of passages\n" +
          "of Lorem Ipsum available.",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      isFavorite:false,
      file:{
          image:ASSETS.Xls,
          text:"exercises_complete.xls"
      }
  },
]

exports.comments = [
  {
      id:1,
      image:ASSETS.CommentOne,
      name:"Andrew Smith",
      comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
  },
  {
      id:2,
      image:ASSETS.CommentTwo,
      name:"William John",
      comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
  },
  {
      id:3,
      image:ASSETS.CommentThree,
      name:"Emily Smith",
      comment:"It is a long established fact that a reader will be distracted"
  },
  {
      id:4,
      image:ASSETS.CommentFour,
      name:"Alexandra",
      comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
  },
  {
      id:5,
      image:ASSETS.CommentFive,
      name:"Samantha",
      comment:"Lorem Ipsum is distribution of letters."
  },
  {
      id:6,
      image:ASSETS.CommentTwo,
      name:"William John",
      comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
  },
  {
      id:7,
      image:ASSETS.CommentOne,
      name:"Andrew Smith",
      comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
  },
  {
      id:8,
      image:ASSETS.CommentTwo,
      name:"William John",
      comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
  },
  {
      id:9,
      image:ASSETS.CommentThree,
      name:"Emily Smith",
      comment:"It is a long established fact that a reader will be distracted"
  },
  {
      id:10,
      image:ASSETS.CommentFour,
      name:"Alexandra",
      comment:"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
  },
  {
      id:11,
      image:ASSETS.CommentFive,
      name:"Samantha",
      comment:"Lorem Ipsum is distribution of letters."
  },
  {
      id:12,
      image:ASSETS.CommentTwo,
      name:"William John",
      comment:"Lorem Ipsum is that it has a more-or-less normal distribution of letters."
  },
]

exports.studentBadges = [
  {
      id:1,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      goalText:"Topper In Science",
      isFavorite:true,
      goalPicture:ASSETS.BadgeOne
  },
  {
      id:2,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      goalText:"Masters In Chess",
      isFavorite:true,
      goalPicture:ASSETS.BadgeTwo
  },
  {
      id:3,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      goalText:"Won the School Cricket Tournament",
      isFavorite:false,
      goalPicture:ASSETS.BadgeThree
  },
  {
      id:4,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      interactions:{
          likes:"124",
          comments:[],
          shares:"8",
      },
      goalText:"100% Attendance",
      isFavorite:true,
      goalPicture:ASSETS.BadgeFour
  },
]

exports.questions =[
  {
      id:1,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      answers:2,
      interactions:{
          likes:"124",
          answers:[
            {
                id:1,
                name:"Samantha",
                description:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected\n" +
                    "humour, or randomised words which don't look even slightly believable.",
                picture:ASSETS.CommentFive,
                interactions:{
                    likes:"124",
                    answers:[],
                    shares:"8",
                },
                postedTime:"10 mins ago",
                allowedTo:"Public",
            },
            {
                id:2,
                name:"John Doe",
                description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of\n" +
                    "using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.",
                picture:ASSETS.Man1Photo,
                interactions:{
                    likes:"124",
                    answers:[],
                    shares:"8",
                },
                postedTime:"10 mins ago",
                allowedTo:"Public",
            },
            {
                id:3,
                name:"Andrew Smith",
                description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                picture:ASSETS.Man3Photo,
                interactions:{
                    likes:"124",
                    answers:[],
                    shares:"8",
                },
                postedTime:"10 mins ago",
                allowedTo:"Public",
            },
            {
                id:4,
                name:"Shaloni Rajput",
                description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                picture:ASSETS.CommentFour,
                interactions:{
                    likes:"124",
                    answers:[],
                    shares:"8",
                },
                postedTime:"10 mins ago",
                allowedTo:"Public",
            }
        ],
          shares:"8",
      },
      goalText:"Do you agree with me that our world would be a better place without gods?",
      isFavorite:true,
  },
  {
      id:2,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      answers:-1,
      interactions:{
          likes:"124",
          answers:[],
          shares:"8",
      },
      goalText:"Is there anything wrong with being a janitor after high school?",
      isFavorite:false,
  },
  {
      id:3,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      answers:-1,
      interactions:{
          likes:"124",
          answers:[],
          shares:"8",
      },
      goalText:"Where do companies go wrong?",
      isFavorite:false,
  },
  {
      id:4,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      answers:-1,
      interactions:{
          likes:"124",
          answers:[],
          shares:"8",
      },
      goalText:"How do I select the right school furniture?",
      isFavorite:false,
  },
  {
      id:5,
      name:"Ganesh Aacharya",
      picture: ASSETS.PostPicture,
      postedTime:"10 mins ago",
      allowedTo:"Public",
      answers:-1,
      interactions:{
          likes:"124",
          answers:[],
          shares:"8",
      },
      goalText:"Do you agree with me that our world would be a better place without gods?",
      isFavorite:false,
  },
]

exports.recents = [
  {
      id:1,
      image:ASSETS.CommentOne,
      name:"Andrew Smith"
  },
  {
      id:2,
      image:ASSETS.CommentTwo,
      name:"William John"
  },
  {
      id:3,
      image:ASSETS.CommentThree,
      name:"John Doe"
  },
  {
      id:4,
      image:ASSETS.CommentFour,
      name:"Emily Smith"
  },
  {
      id:5,
      image:ASSETS.CommentFive,
      name:"Alexandra Smith"
  },
]

exports.topStudents = [
  {
      id:1,
      name:"Samantha",
      image:ASSETS.CommentOne,
      grade:"Std. 11 A"
  },
  {
      id:2,
      name:"Vikram Batra",
      image:ASSETS.CommentTwo,
      grade:"Std. 11 A"
  },
  {
      id:3,
      name:"Mark Smith",
      image:ASSETS.CommentThree,
      grade:"Std. 11 A"
  },
  {
      id:4,
      name:"Calvin George",
      image:ASSETS.CommentFour,
      grade:"Std. 11 A"
  },
  {
      id:5,
      name:"Samantha",
      image:ASSETS.CommentOne,
      grade:"Std. 11 A"
  },
  {
      id:6,
      name:"Samantha",
      image:ASSETS.CommentFour,
      grade:"Std. 11 A"
  },
  {
      id:7,
      name:"John Doe",
      image:ASSETS.CommentThree,
      grade:"Std. 11 A"
  },
  {
      id:8,
      name:"Mark Smith",
      image:ASSETS.CommentThree,
      grade:"Std. 11 A"
  },
  {
      id:9,
      name:"Samantha",
      image:ASSETS.CommentOne,
      grade:"Std. 11 A"
  }
]

exports.replies = [
  [
      {
          id:1,
          name:"Andrew Smith",
          comment:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
          image:ASSETS.CommentOne
      },
      {
          id:2,
          name:"William Smith",
          comment:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
          image:ASSETS.CommentFour
      }
  ],[],[],[]
]

exports.subjectBoxes = [
  "English","Math"
]

exports.widgets = [
  "Profile", "Notifications" , "News" , "Events" , "Contacts", "Chat", "Blogs" , "Goals", "Calendar", "Other(Students)", "Other(Teachers)"
]

exports.news = [
  {
      id:1,
      title:"The exercises complete",
      content:"Lorem Ipsum is simply dummy text of the\n" +
          "printing and typesetting industry.",
      createdAt: "1 Dec 2022"
  },
  {
      id:2,
      title:"Long established fact",
      content:"Lorem Ipsum is simply dummy text of the\n" +
          "printing and typesetting industry.",
      createdAt: "1 Dec 2022"
  },
  {
      id:3,
      title:"Typesetting industry",
      content:"Lorem Ipsum is simply dummy text of the\n" +
          "printing and typesetting industry.",
      createdAt: "1 Dec 2022"
  }
]

exports.chatData = [
  { id: 1, profileImage: ASSETS.Man1Photo, title: "Andrew Smith" },
  { id: 2, profileImage: ASSETS.Man42Photo, title: "William John" },
  { id: 3, profileImage: ASSETS.CommentThree, title: "Alexandra Smith" },
  { id: 4, profileImage: ASSETS.Man2Photo, title: "John Doe" },
  { id: 5, profileImage: ASSETS.CommentTwo, title: "Emily Smith" },
  { id: 6, profileImage: ASSETS.Man1Photo, title: "Andrew Smith" },
  { id: 7, profileImage: ASSETS.Man3Photo, title: "William John" },
  { id: 8, profileImage: ASSETS.CommentThree, title: "Alexandra Smith" },
  { id: 9, profileImage: ASSETS.Man2Photo, title: "John Doe" },
  { id: 10, profileImage: ASSETS.CommentTwo, title: "Emily Smith" },
]

// Customizable Area End