import React, {Component} from 'react';
import SinglePost, { IAnswer, IPost } from './SinglePost';
import { IProps as IProfileSummaryViewProps } from "./ProfileSummaryView.web";
import { ACTIVE_LANDING_PAGE_BOXED_VIEW, ACTIVE_LANDING_PAGE_TAB } from "./types";
import {
    Grid
  } from "@material-ui/core";
import AddQuestionBox from './AddQuestionBox';

type MyProps = {
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    user:Omit<IProfileSummaryViewProps, "size"> & { biography: string };
    boxedView:ACTIVE_LANDING_PAGE_BOXED_VIEW;
    questions:IPost[];
    studentAnswers:IAnswer[] | undefined;
    handleHidePost:(id:number)=>void;
    handleOpenAnswerView:(question:IPost)=>void;
    isAnswerView:boolean;
}
type MyState = {}

class DiscussionForumTab extends Component<MyProps,MyState> {
    render(){
        if(this.props.activeTab === 'DISCUSSION') {
            return(
                <Grid container item spacing={2}>
                    {this.props.user.profileType === "myProfile" && <AddQuestionBox/>}
                    {this.props.questions.map((item: IPost) => (
                        <SinglePost
                            handleOpenAnswerView={this.props.handleOpenAnswerView}
                            isAnswerView={this.props.isAnswerView}
                            hidePost={this.props.handleHidePost}
                            activeTab={this.props.activeTab}
                            answerAction="dialog"
                            boxedView={this.props.boxedView}
                            key={item.id}
                            item={item}
                        />
                    ))}
                    {this.props.isAnswerView && this.props.studentAnswers &&
                        this.props.studentAnswers.map((item: any) => (
                        <SinglePost
                            hidePost={this.props.handleHidePost}
                            activeTab={this.props.activeTab}
                            answerAction="expand"
                            boxedView="QUESTION"
                            key={item.id}
                            item={item}
                        />
                    ))}
                </Grid>
            )
        }
        else return <></>
    }
}

export default DiscussionForumTab
