import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { ChooseImage, Camera } from './assets/images';
import GalleryView from './GalleryView.web';
import CapturePhoto from './CapturePhoto.web';
import './assets/styles/AddToDialogContent.css';

const IMAGE_LIST = [
  'https://picsum.photos/200/300',
  'https://picsum.photos/200/400',
  'https://picsum.photos/1200/1800',
  'https://picsum.photos/500/700',
  'https://picsum.photos/300/300',
  'https://picsum.photos/400/300',
  'https://picsum.photos/500/250',
  'https://picsum.photos/800/800',
  'https://picsum.photos/200/250',
  'https://picsum.photos/600/450',
  'https://picsum.photos/500/150',
  'https://picsum.photos/900/600',
  'https://picsum.photos/2042/1024',
  'https://picsum.photos/450/150',
  'https://picsum.photos/200/300',
  'https://picsum.photos/200/400',
  'https://picsum.photos/1200/1800',
  'https://picsum.photos/500/700',
  'https://picsum.photos/300/300',
  'https://picsum.photos/400/300',
  'https://picsum.photos/500/250',
  'https://picsum.photos/800/800',
  'https://picsum.photos/200/250',
  'https://picsum.photos/600/450',
  'https://picsum.photos/500/150',
  'https://picsum.photos/900/600',
  'https://picsum.photos/2042/1024',
  'https://picsum.photos/450/150',
];

class AddToDialogContent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { view: null };

    this.selectImage = this.selectImage.bind(this);
    this.onPhotoCaptured = this.onPhotoCaptured.bind(this);
    this.onViewChange = this.onViewChange.bind(this);
  }

  onViewChange(view: TDialogView) {
    this.setState({ view });
  }

  selectImage(imageURLS: string[]) {
    this.setState({ view: null }, () => {
      this.props.onImagesSelected(imageURLS);
    });
  }

  onPhotoCaptured(imageUrl: string) {
    this.setState({ view: null }, () => {
      this.props.onImagesSelected([imageUrl]);
    });
  }

  render() {
    return (
      <div className="add-to-dialog-wrapper" data-view={this.state.view}>
        <div className="dialog-title">
          <Typography component="h6">{this.props.title}</Typography>
        </div>

        <div className="dialog-content">
          {this.state.view === null && (
            <div className="view-picker">
              <Button
                className="add-to-dialog-option-button"
                variant="text"
                startIcon={<img src={ChooseImage} width={70} height={70} />}
                onClick={() => this.onViewChange('GALLERY')}
              >
                <span className="button-text">Choose From Gallery</span>
              </Button>

              <Button
                className="add-to-dialog-option-button"
                variant="text"
                startIcon={<img src={Camera} width={70} height={66} />}
                onClick={() => this.onViewChange('CAMERA')}
              >
                <span className="button-text">Take Photo</span>
              </Button>
            </div>
          )}

          {this.state.view === 'GALLERY' && (
            <GalleryView
              images={IMAGE_LIST}
              onImagesSelected={this.selectImage}
              isMultipleSelection={this.props.isMultipleImageSelection}
            />
          )}

          {this.state.view === 'CAMERA' && (
            <CapturePhoto
              onPhotoCaptured={this.onPhotoCaptured}
            />
          )}
        </div>
      </div>
    )
  }
}

type TDialogView = 'GALLERY' | 'CAMERA';

interface IProps {
  title: string;
  onImagesSelected: (imageURL: string[]) => void;
  isMultipleImageSelection?: boolean;
}

interface IState {
  view: TDialogView | null
}

export default AddToDialogContent;