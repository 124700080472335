import React, {Component} from 'react';
import { Box } from "@material-ui/core";
import "./assets/styles/topStudentsMenu.css"
import { configJSON } from '../../blocks/landingpage/src/LandingPageController';

interface ITopStudent {
    id:number,
    name:string,
    image:string,
    grade:string,
}

type MyProps = {

}
type MyState = {

}

class TopStudentsMenu extends Component<MyProps,MyState> {
    constructor(props:any){
        super(props);
    }

    render(){
        return (
            <Box component="div" className="top-students-menu-container" bgcolor="primary.main">
                <h3>Top 10 Students</h3>
                {
                    configJSON.topStudents.map((item:ITopStudent)=>(
                        <div className="top-students-card">
                            <img src={item.image} alt=""/>
                            <div className="top-students-info">
                                <h4>{item.name}</h4>
                                <span>{item.grade}</span>
                            </div>
                        </div>
                    ))
                }
            </Box>
        );
    }
}

export default TopStudentsMenu;
