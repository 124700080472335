import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  TextField,
  InputAdornment,
  Paper,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import GenericCard from "../../../components/src/GenericCard.web";
import Avatar from "../../../components/src/BorderedAvatar.web";
import Pagination from "../../../components/src/Pagination.web";
import PageHeader from "../../../components/src/PageHeader.web";
import MainContainer from "../../../components/src/containers/MainContainer.web";
import BorderColoredTextfield from "../../../components/src/BorderColoredTextfield";
import {Form, Formik} from "formik";
import { TrashIcon, DocIcon } from "./assets";
import "../../../components/src/assets/styles/normalize.css";
import "./NotificationsPage.css";

// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainContainer>
        <PageHeader navigation={this.props.navigation} pageTitle={configJSON.pageTitle} isGoBack={true}>
          <Button
            color="primary"
            variant="contained"
            id="mark-all-notifications-as-read-button"
          >
            {configJSON.markAllAsRead}
          </Button>
        </PageHeader>

        <GenericCard id="notifications-page">
          <Box className={`search-area border-colored-text-field-container`}>
            <Formik
                  data-test-id="formik"
                  initialValues={{search:""}}
                  onSubmit={(values, actions) => {
                      actions.setSubmitting(false);
                      actions.resetForm()
                  }}
              >
                <Form className="student-search-form" translate="yes">
                  <BorderColoredTextfield
                    placeholder={configJSON.searchTextFieldPlaceholder}
                    name="search"
                    className="search-area-textfield"
                    icon={<SearchOutlined className="search-icon input-icon" />}
                  />
                </Form>
            </Formik>
          </Box>

          <Box className="notification-list">
            {Array.from(Array(2)).map((group, idx) => (
              <Box className="notification-group" key={idx}>
                <Box className="group-title">
                  <Typography component="h6" className="group-title-text">
                    {idx === 0
                      ? configJSON.todayText
                      : configJSON.yesterdayText}
                  </Typography>
                  {idx === 0 && (
                    <Button
                      variant="text"
                      color="primary"
                      className="clear-all-button"
                      data-test-id="clear-all-button"
                    >
                      {configJSON.clearAll}
                    </Button>
                  )}
                </Box>

                <Box className="item-list">
                  {this.state.data.map((item) => {
                    const hasDynamicValue =
                      item.text.split(/({{.+?}})/gim).length > 1;

                    return (
                      <Paper
                        key={item.id}
                        className="notification-item"
                        data-test-id="notification-item"
                        color="primary"
                        elevation={0}
                        square
                      >
                        <Box className="item-wrapper">
                          <Avatar
                            className="item-avatar"
                            src={item.profileImage}
                            width={configJSON.notificationAvatarWidth}
                            height={configJSON.notificationAvatarHeight}
                          />

                          <Box className="item-content-section">
                            <Box className="content-area">
                              <Typography component="strong">
                                {item.fullname}
                              </Typography>
                              <Typography component="p">
                                {hasDynamicValue &&
                                  item.text
                                    .split(/({{.+?}})/gim)
                                    .map((textItem, idx) => {
                                      if (
                                        textItem.startsWith("{{") &&
                                        textItem.endsWith("}}")
                                      ) {
                                        return (
                                          <mark key={idx}>
                                            {textItem.substring(
                                              2,
                                              textItem.length - 2
                                            )}
                                          </mark>
                                        );
                                      }

                                      return (
                                        <span key={idx}>{textItem}</span>
                                      );
                                    })}

                                {!hasDynamicValue && item.text}
                              </Typography>

                              {Array.isArray(item.attachments) &&
                                item.attachments.length > 0 && (
                                  <Box className="attachments">
                                    {item.attachments.map(
                                      (attachment, idx) => (
                                        <div
                                          className="attachment-item"
                                          key={idx}
                                        >
                                          <a
                                            href={attachment.downloadLink}
                                            download={attachment.filename}
                                          >
                                            <Button
                                              variant="text"
                                              startIcon={
                                                <img
                                                  src={DocIcon}
                                                  width={
                                                    configJSON.docIconWidth
                                                  }
                                                  height={
                                                    configJSON.docIconHeight
                                                  }
                                                />
                                              }
                                            >
                                              <div>
                                                <Typography
                                                  component="span"
                                                  className="filename"
                                                >
                                                  {attachment.filename}
                                                </Typography>
                                                <Typography
                                                  component="small"
                                                  className="file-size"
                                                >
                                                  {attachment.sizeText}
                                                </Typography>
                                              </div>
                                            </Button>
                                          </a>
                                        </div>
                                      )
                                    )}
                                  </Box>
                                )}
                            </Box>

                            <Box className="date-and-remove-area">
                              <Typography
                                component="b"
                                className="notification-since-date"
                              >
                                {this.timeSince(item.createdAt.toString())}
                              </Typography>
                              <IconButton
                                className="remove-button"
                                data-test-id="remove-button"
                                onClick={() =>
                                  this.deleteNotifications(item.id)
                                }
                              >
                                <img
                                  src={TrashIcon}
                                  width={configJSON.trashIconWidth}
                                  height={configJSON.trashIconHeight}
                                  alt="trash-icon"
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </Box>

          <Box className="pagination-wrapper">
            <Pagination count={5} />
          </Box>
        </GenericCard>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
