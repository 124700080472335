import React, { Component } from "react";
import { Button } from '@material-ui/core';
import { classNames } from "./utilities/Utils";
import ImageCarouselDialog from './ImageCarouselDialog';
import { CloseOutlined } from '@material-ui/icons';
import { withTheme, Theme } from '@material-ui/core/styles';

type MyProps = {
    images: string[],
    theme:Theme,
    /* onImageRemove?: (id:number)=>void,
    onImageClicked: (id:number)=>void, */
    deletable?:boolean,
}
type MyState = {
    imageUrls: string[]
}

class PostImages extends Component<MyProps,MyState>{
    private contentImageCarouselRef = React.createRef<ImageCarouselDialog>();

    constructor(props: any) {
        super(props);

        this.onImageRemove = this.onImageRemove.bind(this);
    
        this.state = {
          imageUrls: this.props.images,
        }
      }

    onImageRemove(idx: number) {
        this.setState((prevState) => ({
          ...prevState,
          imageUrls: prevState.imageUrls.filter((image, index) => idx !== index)
        }), () => {
          const isDialogOpen = this.contentImageCarouselRef.current?.isDialogOpened();
    
          if (isDialogOpen && this.state.imageUrls.length === 0) {
            this.contentImageCarouselRef.current?.handleCloseModal();
          }
        })
      }
    
    onImageClicked(idx: number) {
        this.contentImageCarouselRef.current?.openDialog(idx);
    }

    render(){
        const {imageUrls} = this.state
        const {deletable} = this.props
        return(
            <div
                className={classNames({
                    'image-list': true,
                    '__one-item': imageUrls.length === 1,
                    '__two-items': imageUrls.length === 2,
                    '__three-items': imageUrls.length >= 3,
                    '__higher-than-three-items': imageUrls.length > 3
                })}
                style={{ borderColor: this.props.theme.palette.primary.main }}
            >
                {imageUrls.slice(0, 3).map((image, idx) => (
                    <div className="image-item" key={`${image}_${idx}`}>
                    <img src={image} alt="banner" onClick={() => this.onImageClicked(idx)} />
                        {(idx < 2 || (idx === 2 && imageUrls.length === 3)) && deletable && (
                            <Button
                              className="closeButton"
                              onClick={() => deletable && this.onImageRemove(idx)}
                              color="primary"
                              variant="contained"
                            >
                                <CloseOutlined />
                            </Button>
                        )}
                        {(idx === 2 && imageUrls.length > 3) && (
                            <div className="images-count">
                                <span>+{imageUrls && imageUrls.length - 3}</span>
                            </div>
                        )}
                    </div>
                ))}
                <ImageCarouselDialog
                    deletable={this.props.deletable}
                    ref={this.contentImageCarouselRef}
                    images={imageUrls ? imageUrls.map((item)=>item) : []}
                    onImageRemove={this.onImageRemove}
                />
            </div>
        )
    }
}

export default withTheme(PostImages)