export const StoryMain = require("../assets/story-main.png");
export const StoryMainTwo = require("../assets/story-main-2.png");
export const StoryMainThree = require("../assets/story-main-3.png");
export const StoryMainFour = require("../assets/story-main-4.png");
export const StoryMainFive = require("../assets/story-main-5.png");
export const StoryMini = require("../assets/story-mini.png");
export const StoryMiniTwo = require("../assets/story-mini-2.png");
export const StoryMiniThree = require("../assets/story-mini-3.png");
export const StoryMiniFour = require("../assets/story-mini-4.png");
export const StoryMiniFive = require("../assets/story-mini-5.png");
export const PostImage = require("../assets/post-image.png");
export const PostImageTwo = require("../assets/post-image-2.png");
export const PostImageThree = require("../assets/post-image-3.png");
export const PostVideoImage = require("../assets/post-video-image.png");
export const PostPicture = require("../assets/post-picture.png");
export const Certificate = require("../assets/certificate.png");
export const CertificateTwo = require("../assets/certificate-2.png");
export const ProfilePicture = require("../assets/profilepicture.png");
export const Man1Photo = require("../assets/man1.png");
export const Man2Photo = require("../assets/man2.png");
export const Man3Photo = require("../assets/man3.png");
export const Man42Photo = require("../assets/man4.png");
export const Man5Photo = require("../assets/man5.png");
export const Woman1Photo = require("../assets/woman1.png");
export const Woman2Photo = require("../assets/woman2.png");
export const Xls = require("../assets/xls.png");
export const Doc = require("../assets/doc.png");
export const Pdf = require("../assets/pdf.png");
export const CommentOne = require("../assets/comments.png");
export const CommentTwo = require("../assets/comments-2.png");
export const CommentThree = require("../assets/comments-3.png");
export const CommentFour = require("../assets/comments-4.png");
export const CommentFive = require("../assets/comments-5.png");
export const PostVideoImageThree = require("../assets/post-video-image-3.png");
export const VideoIcon = require("../assets/video-icon.png");
export const BadgeOne = require("../assets/badge.png");
export const BadgeTwo = require("../assets/badge-2.png");
export const BadgeThree = require("../assets/badge-3.png");
export const BadgeFour = require("../assets/badge-4.png");
export const BackIcon = require("../assets/back-icon.png");
export const PortfolioImage = require("../assets/portfolio-image.png");
