import { Typography } from '@material-ui/core';
import React from 'react';
import Avatar from './BorderedAvatar.web';
import GenericCard from './GenericCard.web';
import './assets/styles/ContactsWidget.css';

class ContactsWidget extends React.Component<IProps, IState> {
  render() {
    const { contacts } = this.props;

    return (
      <GenericCard id="contacts-widget" title="Contacts">
        {contacts.map(item => (
          <div className="contact-item" key={item.id}>
            <Avatar
              className="contact-avatar"
              src={item.profileImage}
              width={54}
              height={54}
            />

            <Typography className="contact-title">{item.title}</Typography>
          </div>
        ))}
      </GenericCard>
    );
  }
}

export interface IContactWidgetItem {
  id: string | number;
  profileImage: string;
  title: string;
}

interface IProps {
  contacts: IContactWidgetItem[];
}

interface IState {}

export default ContactsWidget;
