import React,{ Component } from "react";
import {
    Button,
    Menu,
    Checkbox,
    FormGroup,
    FormControlLabel
  } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type MyProps = {
    handleOpenWidgetMenu:(event: React.MouseEvent<HTMLButtonElement>)=>void;
    anchorElWidgetMenu:null | HTMLElement;
    handleCloseWidgetMenu:()=>void;
    widgets:string[];
    checkedWidgets:string[];
    handleCheckWidgets:(text:string, check:boolean)=>void;
    handleSaveWidgetSettings:()=>void;
    handleCancelWidgetSettings:()=>void;
}

type MyState = {}

class WidgetMenuButton extends Component<MyProps,MyState>{
    render(){
        const {handleOpenWidgetMenu,anchorElWidgetMenu,handleCloseWidgetMenu,widgets,checkedWidgets,handleCheckWidgets,handleSaveWidgetSettings,handleCancelWidgetSettings} = this.props
        return(
            <>
                <Button
                    data-test-id="widget-button"
                    onClick={handleOpenWidgetMenu}
                    variant="contained"
                    color="primary"
                    className="widget-button"
                >
                    Widget
                </Button>
                <div className="widget-menu-wrapper">
                    <Menu
                        data-test-id="widget-menu"
                        id="widget-menu"
                        anchorEl={anchorElWidgetMenu}
                        keepMounted
                        open={Boolean(anchorElWidgetMenu)}
                        onClose={handleCloseWidgetMenu}
                        className="widget-menu"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: -230,
                            horizontal: 100
                        }}
                    >
                        <div className="widget-menu-container">
                            <FormGroup className="widget-menu-form">
                                {
                                    widgets.map((widget: string) => (
                                    <React.Fragment key={widget}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={widget}
                                                    onChange={e =>
                                                        handleCheckWidgets(widget, e.target.checked)
                                                    }
                                                    color="primary"
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={
                                                        checkedWidgets.indexOf(widget) > -1
                                                    }
                                                />
                                            }
                                            label={widget}
                                        />
                                    </React.Fragment>
                                ))}
                            </FormGroup>
                            <div className="widget-menu-buttons">
                                <Button
                                    onClick={handleSaveWidgetSettings}
                                    className="widget-button"
                                    variant="contained"
                                    color="primary"
                                >
                                    Ok
                                </Button>
                                <Button
                                    onClick={handleCancelWidgetSettings}
                                    className="widget-button"
                                    variant="contained"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Menu>
                </div>
            </>
        )
    }
}

export default WidgetMenuButton