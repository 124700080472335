Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const ASSETS = require('./assets');

exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!";

exports.pageTitle = "Notifications";
exports.markAllAsRead = "Mark all as read";
exports.searchTextFieldPlaceholder = "Search for notification...";
exports.clearAll = "Clear All";
exports.trashIconWidth = 18;
exports.trashIconHeight = 20;
exports.docIconWidth = 28;
exports.docIconHeight = 34; 
exports.notificationAvatarWidth = 60;
exports.notificationAvatarHeight = 60;
exports.searchFieldIconPosition = "end";

// MOCK DATA
exports.todayText = "Today";
exports.yesterdayText = "Yesterday";
exports.notificationsDummyData = [
  {
    id: Math.random(),
    fullname: 'Andrew Smith',
    profileImage: ASSETS.Man1Photo,
    text: `Lorem Ipsum is simply {{dummy text}} of the printing and typesetting industry. Lorem Ipsum has been the {{industry's standard}} dummy text ever since the 1500s.`,
    createdAt: new Date(Date.now() - (60 * 20 * 1000))
  }, {
    id: Math.random(),
    fullname: 'William John',
    profileImage: ASSETS.Man1Photo,
    text: `Add to files to Assigment`,
    createdAt: new Date(Date.now() - (60 * 40 * 1000)),
    attachments: [{
      filename: 'Chloe_Epelbaum_Essay.docx',
      downloadLink: 'https://www.builder.ai/images/Group-17612-1.svg',
      sizeText: '100 KB Word Document',
      type: 'docx'
    }, {
      filename: 'Chloe_Epelbaum_Essay.docx',
      downloadLink: 'https://www.builder.ai/images/Group-17612-1.svg',
      sizeText: '100 KB Word Document',
      type: 'docx'
    }]
  }, {
    id: Math.random(),
    fullname: 'John Doe',
    profileImage: ASSETS.Man1Photo,
    createdAt: new Date(Date.now() - (60 * 70 * 1000 * 24)),
    text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quaerat iure ullam aliquid iusto provident nobis illum! Alias praesentium, hic inventore ipsa ullam unde impedit, dicta quas minima recusandae, libero dolor?'
  }, {
    id: Math.random(),
    fullname: 'William John',
    profileImage: ASSETS.Man1Photo,
    text: `Add to files to Assigment`,
    createdAt: new Date(Date.now() - (60 * 40 * 1000)),
    attachments: [{
      filename: 'Chloe_Epelbaum_Essay.docx',
      downloadLink: 'https://www.builder.ai/images/Group-17612-1.svg',
      sizeText: '100 KB Word Document',
      type: 'docx'
    }]
  }
];
// Customizable Area End