import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import './assets/styles/PageHeader.css';

class PageHeader extends React.Component<React.PropsWithChildren<IProps>, IState> {
  render() {
    const { pageTitle, isGoBack = true, children, navigation } = this.props;

    return (
      <Box id="page-header">
        <div className="left-side">
          <Button
            onClick={()=>navigation.goBack()}
            className="page-header-button"
            variant="text"
            startIcon={isGoBack && <KeyboardBackspace className="go-back-icon" />}
          >
            <Typography className="page-header-button-text" component="span">{pageTitle}</Typography>
          </Button>
        </div>

        {children && (
          <div className="right-side">
            {children}
          </div>
        )}
      </Box>
    )
  }
}

interface IProps {
  pageTitle: string;
  isGoBack?: boolean;
  navigation?: any
}

interface IState {}

export default PageHeader;