import React from 'react';
import { Avatar, Divider, Button, Box, IconButton, Badge } from '@material-ui/core';
import { ProfilePicture, HeaderCheckIcon, HeaderEnglishLang, HeaderSupportIcon, HeaderFontIcon } from './assets/images';
import { ChevronLeftOutlined, Notifications } from '@material-ui/icons';
import { withTheme, Theme } from '@material-ui/core/styles';
import './assets/styles/Header.css';
import PageHeader from './PageHeader.web';

class Header extends React.Component<IProps, IState>{
  constructor(props: IProps) {
    super(props);

    this.toggleActionsVisibility = this.toggleActionsVisibility.bind(this);
    this.state = { showActions: false }
  }

  toggleActionsVisibility() {
    this.setState((prevState) => ({
      ...prevState,
      showActions: !prevState.showActions
    }))
  }

  render() {
    const { theme, usePageHeader = false, pageTitle = '', navigation } = this.props;
    const { showActions } = this.state;

    const HeaderWrapper = (
      <Box className="header-wrapper">
        <div className={`action-icons ${showActions ? '__show' : ''}`}>
          <div className="done-action action-item-wrapper">
            <IconButton>
              <img src={HeaderCheckIcon} alt="" width={24} height={24} />
            </IconButton>
          </div>

          <div className="notifications-action action-item-wrapper">
            <IconButton>
              <Badge color="error" badgeContent={0} showZero>
                <Notifications />
              </Badge>
            </IconButton>
          </div>

          <div className="support-action action-item-wrapper">
            <IconButton>
              <img src={HeaderSupportIcon} alt="" width={24} height={24} />
            </IconButton>
          </div>

          <div className="font-action action-item-wrapper">
            <IconButton>
              <img src={HeaderFontIcon} alt="" width={28} height={28} />
            </IconButton>
          </div>

          <div className="language-action action-item-wrapper">
            <IconButton>
              <img src={HeaderEnglishLang} alt="" width={24} height={24} />
            </IconButton>
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="profile-button"
          data-test-id="profile-button"
          disableElevation
          style={{ backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.light})` }}
          onClick={this.toggleActionsVisibility}
        >
          <ChevronLeftOutlined />
        </Button>

        <Avatar
          src={ProfilePicture}
          alt="profile picture"
          className="profile-picture"
        />
      </Box>
    );

    return (
      <div className="header-container">
        <header id="app-header">
          {usePageHeader ? (
            <PageHeader
              navigation={navigation}
              pageTitle={pageTitle}
              isGoBack
            >
              {HeaderWrapper}
            </PageHeader>
          ) : (
            HeaderWrapper
          )}
        </header>
  
        <Divider style={{ marginBottom: 28 }} />
      </div>
    );
  }
};

interface IProps {
  theme: Theme;
  usePageHeader?: boolean;
  pageTitle?: string;
  navigation?:any;
}

interface IState {
  showActions: boolean;
}

export default withTheme(Header);
