import React from 'react';

const FileListIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 24, height = 24, color } = props;
    return(
        <svg version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width={width}
            height={height}
            xmlSpace="preserve"
        >
            <g>
                <path style={{ fill: color }} d="M213.22,11.51c-4.52-3.05-9.51-5.47-14.84-7.13c-5.33-1.66-11.01-2.55-16.83-2.55H56.63
                    c-7.77,0-15.26,1.58-22.05,4.46c-10.19,4.31-18.8,11.47-24.9,20.5C6.63,31.3,4.21,36.3,2.55,41.63C0.89,46.96,0,52.64,0,58.46
                    v395.07c0,7.77,1.58,15.26,4.46,22.05c4.31,10.19,11.47,18.8,20.5,24.9c4.52,3.05,9.51,5.47,14.84,7.13
                    c5.33,1.66,11.01,2.55,16.83,2.55h124.91c7.77,0,15.26-1.58,22.05-4.46c10.19-4.31,18.8-11.47,24.9-20.5
                    c3.05-4.52,5.47-9.51,7.13-14.84c1.66-5.33,2.55-11.01,2.55-16.83V58.46c0-7.77-1.58-15.26-4.46-22.05
                    C229.41,26.23,222.25,17.62,213.22,11.51z M203.98,453.54c0,3.14-0.63,6.05-1.76,8.73c-1.69,4-4.56,7.46-8.15,9.88
                    c-1.79,1.21-3.76,2.16-5.85,2.81c-2.1,0.65-4.32,1-6.68,1.01H56.63c-3.14,0-6.05-0.63-8.73-1.76c-4-1.69-7.46-4.56-9.88-8.15
                    c-1.21-1.79-2.16-3.76-2.82-5.85c-0.65-2.1-1-4.32-1.01-6.68V58.46c0-3.14,0.63-6.05,1.76-8.73c1.69-4,4.56-7.46,8.15-9.88
                    c1.79-1.21,3.76-2.16,5.85-2.82c2.1-0.65,4.32-1,6.68-1.01h124.91c3.14,0,6.05,0.63,8.73,1.76c4,1.69,7.46,4.56,9.88,8.15
                    c1.21,1.79,2.16,3.76,2.82,5.85c0.65,2.1,1,4.32,1.01,6.68V453.54z" />
                <path style={{ fill: color }} d="M507.54,36.42c-4.31-10.19-11.47-18.8-20.5-24.9c-4.52-3.05-9.51-5.47-14.84-7.13
                    c-5.33-1.66-11.01-2.55-16.83-2.55H330.45c-7.77,0-15.27,1.58-22.05,4.46c-10.19,4.31-18.8,11.47-24.9,20.5
                    c-3.05,4.52-5.47,9.51-7.13,14.84c-1.66,5.33-2.55,11.01-2.55,16.83v228.63c0,7.77,1.58,15.26,4.46,22.05
                    c4.31,10.19,11.47,18.8,20.5,24.9c4.52,3.05,9.51,5.47,14.84,7.13c5.33,1.66,11.01,2.55,16.83,2.55h124.91
                    c7.77,0,15.26-1.58,22.05-4.46c10.19-4.31,18.8-11.47,24.9-20.5c3.05-4.52,5.47-9.51,7.13-14.84c1.66-5.33,2.55-11.01,2.55-16.83
                    V58.46C512,50.69,510.42,43.2,507.54,36.42z M477.8,287.09c0,3.14-0.63,6.05-1.76,8.73c-1.69,4-4.56,7.46-8.15,9.88
                    c-1.79,1.21-3.76,2.16-5.85,2.82c-2.1,0.65-4.32,1-6.68,1.01H330.45c-3.14,0-6.05-0.63-8.73-1.76c-4-1.69-7.46-4.56-9.88-8.15
                    c-1.21-1.79-2.16-3.76-2.82-5.85c-0.65-2.1-1-4.32-1.01-6.68V58.46c0-3.14,0.63-6.05,1.76-8.73c1.69-4,4.56-7.46,8.15-9.88
                    c1.79-1.21,3.76-2.16,5.85-2.82c2.1-0.65,4.32-1,6.68-1.01h124.91c3.14,0,6.05,0.63,8.73,1.76c4,1.69,7.46,4.56,9.88,8.15
                    c1.21,1.79,2.16,3.76,2.82,5.85c0.65,2.1,1,4.32,1.01,6.68V287.09z" />
            </g>
        </svg>
    )
}

export default FileListIcon;