import React, {Component, ReactNode} from 'react';
import {Dialog, DialogContent, IconButton} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import "./assets/styles/dialogWrapper.css"
import {PortfolioDownload} from "./assets/images"

type MyProps = {
    isOpen:boolean,
    handleCloseModal: () => void,
    children: ReactNode,
    downloadPdfDocument?: ()=>void | undefined
    id?: string
}
type MyState = {}

class DialogWrapper extends Component<MyProps, MyState> {
    constructor(props:any) {
        super(props);
    }
    render() {
        const {isOpen,handleCloseModal,children,downloadPdfDocument} = this.props
        return (
            <Dialog
                open={isOpen}
                scroll="body"
                onClose={handleCloseModal}
                maxWidth="lg"
                className="dialog-container"
                id={this.props.id || ''}
            >
                <DialogContent className="dialog-container">
                    {
                        downloadPdfDocument === undefined ?
                        <IconButton onClick={handleCloseModal} disableRipple className="close-dialog-button">
                            <Close htmlColor="rgba(0, 0, 0, 0.1)" />
                        </IconButton> :
                            <IconButton onClick={downloadPdfDocument} disableRipple className="download-dialog-button">
                                <img src={PortfolioDownload} alt=""/>
                            </IconButton>

                    }
                    {children}
                </DialogContent>
            </Dialog>
        )
    }
}

export default DialogWrapper;
