import React,{Component} from "react"
import {Doc, Pdf, Xls} from "./assets/images";
import {IconButton} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
type MyProps = {
    handleDeleteFile:(index:number)=>void,
    file:any,
    index:number
}
type MyState = {

}
class FileListItem extends Component<MyProps,MyState>{
    constructor(props:any){
        super(props)
    }
    render(){
        const {handleDeleteFile,file,index} = this.props
        return(
            <div className="file-list-item" key={file.name}>
                <div>
                    {
                        file.name.toLowerCase().includes("pdf") &&
                        <img className="file-icon" src={Pdf} alt=""/>
                    }
                    {
                        file.name.toLowerCase().includes("docx") &&
                        <img className="file-icon" src={Doc} alt=""/>
                    }
                    {
                        file.name.toLowerCase().includes("xls") &&
                        <img className="file-icon" src={Xls} alt=""/>
                    }
                    <span className="file-name">{file.name}</span>
                </div>
                <div>
                    {/*<span className="file-upload-time"></span>*/}
                    <IconButton onClick={()=>handleDeleteFile(index)} disableRipple className="delete-file-button">
                        <Close width={20} htmlColor="rgba(0, 0, 0, 0.1)" />
                    </IconButton>
                </div>
            </div>
        )
    }
}

export default FileListItem
