export const COLORS = {
    TEACHER: {
      primary: {
        main: '#7f6cfc',
        light: '#aa97fc',
        dark: '#'
      },
      divider: 'rgba(0, 0, 0, 0.17)'
    },
    STUDENT: {
      primary: {
        main: '#f15c63',
        light: '#faa762',
        dark: '#'
      },
      divider: 'rgba(0, 0, 0, 0.17)'
    }
};
