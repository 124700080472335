import React, {Component} from 'react';
import {
    Grid
  } from "@material-ui/core";
import { ACTIVE_LANDING_PAGE_TAB } from "./types";
import ProfileWidget from './ProfileWidget.web';
import { IProps as IProfileSummaryViewProps } from "./ProfileSummaryView.web";
import NewsWidget, { INewsData } from './NewsWidget';
import OtherMembers from './OtherMembers';
import ChatWidget, { IChatWidgetItem } from './ChatWidget';

type MyProps = {
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    activeWidgets:string[];
    user:Omit<IProfileSummaryViewProps, "size"> & { biography: string };
    userType: "TEACHER" | "STUDENT";
    profileOtherUserPictures:string[];
    news:INewsData[];
    chatData:IChatWidgetItem[];
    navigation:any;
}
type MyState = {}

class LeftWidgets extends Component<MyProps,MyState> {
    render(){
        if(["Profile","News","Other(Teachers)","Other(Students)","Friends","Chat"].some(r=>this.props.activeWidgets.includes(r))) {
            return(
                <Grid item className="right-side">
                    <Grid
                        container
                        style={{ flexDirection: "column" }}
                        spacing={4}
                    >
                        {this.props.activeWidgets.includes("Profile") && (
                            <Grid item>
                                <ProfileWidget
                                    userInfo={this.props.user}
                                    isTeacher={this.props.userType === "TEACHER"}
                                />
                            </Grid>
                        )}
                        {this.props.activeWidgets.includes("Other(Teachers)") && (
                            <Grid item>
                                <OtherMembers
                                    navigation={this.props.navigation}
                                    otherUserPictures={this.props.profileOtherUserPictures} 
                                    title="Other Teachers"
                                />
                            </Grid>
                        )}
                        {this.props.activeWidgets.includes("Other(Students)") && (
                            <Grid item>
                                <OtherMembers
                                    navigation={this.props.navigation}
                                    otherUserPictures={this.props.profileOtherUserPictures} 
                                    title="Other Students"
                                />
                            </Grid>
                        )}
                        {this.props.activeWidgets.includes("News") && (
                            <Grid item>
                                <NewsWidget
                                    data={this.props.news}
                                    title="News"
                                />
                            </Grid>
                        )}
                        {this.props.activeWidgets.includes("Chat") && (
                            <Grid item>
                                <ChatWidget
                                    data={this.props.chatData}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )
        }
        else return <></>
    }
}

export default LeftWidgets
