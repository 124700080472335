import React, {Component} from 'react';
import "./assets/styles/shareToFeedDialog.css";
import DialogWrapper from "./DialogWrapper";
import SinglePostContents from "./SinglePostContents";
import {IPost} from "./SinglePost";
import {Button, Box} from "@material-ui/core";
import {FileIcon, ImageIcon, ProfilePicture} from "./assets/images";
import AddFileDialog from "./AddFileDialog";
import FileListItem from './FileListItem';
import AddToDialogContent from './AddToDialogContent.web';
import TargetGroupSelectBox from './TargetGroupSelectbox.web';

type MyProps = {
    item:IPost,
    isOpen:boolean,
    handleCloseModal: () => void
}
type MyState = {
    isAddFileDialogOpen:boolean,
    isAddPhotoDialogOpen:boolean
    files:any,
    showFiles:boolean,
}

class ShareToFeedDialog extends Component<MyProps, MyState> {
    constructor(props:any) {
        super(props);
        this.state = {
            isAddFileDialogOpen:false,
            isAddPhotoDialogOpen:false,
            files:[],
            showFiles:false
        }
    }

    handleAddFileDialogOpen = () => {
        this.setState({isAddFileDialogOpen:true})
    }

    handleAddFileDialogClose = () => {
        this.setState({isAddFileDialogOpen:false})
    }

    handleAddPhotoDialogOpen = () => {
        this.setState({isAddPhotoDialogOpen:true})
    }

    handleAddPhotoDialogClose = () => {
        this.setState({isAddPhotoDialogOpen:false})
    }

    handleAddFiles = () => {
        this.setState({showFiles:true,isAddFileDialogOpen:false})
    }

    onDrop = (files:any) => {
        this.setState({files})
    };
    handleDeleteFile = (id:number) => {
        this.setState((state)=>({files:state.files.filter((item:any,index:number)=>index!==id)}))
    }

    render() {
        const {isOpen,handleCloseModal,item} = this.props
        const {isAddFileDialogOpen,isAddPhotoDialogOpen,files,showFiles} = this.state
        return (
            <DialogWrapper isOpen={isOpen} handleCloseModal={handleCloseModal}>
                <div className="share-to-feed">
                    <h3 className="share-to-feed-title">Write Post</h3>
                    <div className="share-to-feed-header">
                        <img src={ProfilePicture} alt=""/>  
                        <h3>Albert Smith</h3>
                    </div>
                    <input placeholder="What's new, Albert?" type="text"/>
                    {
                        showFiles &&
                            files.map((file:any,index:number)=>(
                                <FileListItem handleDeleteFile={this.handleDeleteFile} file={file} index={index}/>
                            ))
                    }
                    <Box className="post-information" borderColor="primary.main">
                        <SinglePostContents activeTab="TIMELINE" item={item}/>
                    </Box>
                    <div className="post-actions">
                        <div className="post-actions-item">
                            <Button className="post-actions-button" startIcon={<img src={ImageIcon} alt=""/>} onClick={this.handleAddPhotoDialogOpen} variant="text">Photo/Video</Button>
                            <div className="vertical-line"></div>
                            <Button className="post-actions-button" startIcon={<img src={FileIcon} alt=""/>} onClick={this.handleAddFileDialogOpen} variant="text">File</Button>
                        </div>
                        <div className="post-actions-item">
                            <TargetGroupSelectBox size="normal" />
                            <Button className="post-button" variant="contained" color="primary">Post</Button>
                        </div>
                    </div>
                </div>
                <AddFileDialog handleAddFiles={this.handleAddFiles} files={files} onDrop={this.onDrop} handleDeleteFile={this.handleDeleteFile} isOpen={isAddFileDialogOpen} handleCloseModal={this.handleAddFileDialogClose}/>
                <DialogWrapper isOpen={isAddPhotoDialogOpen} handleCloseModal={this.handleAddPhotoDialogClose} >
                    <AddToDialogContent
                        title="Add Photo"
                        onImagesSelected={this.handleAddPhotoDialogClose}
                    />
                </DialogWrapper>
            </DialogWrapper>
        )
    }
}

export default ShareToFeedDialog;
