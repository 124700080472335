import React, {Component} from 'react';
import {
    Paper,
    withStyles,
    Tabs,
    Tab,
    createStyles,
    Theme
} from "@material-ui/core";
import { ACTIVE_LANDING_PAGE_TAB } from './types';

const classes = {
    root: {
        borderRadius:'8px',
        width:'100%',
        boxShadow:'0 0 15px 0 rgba(0, 0, 0, 0.1)',
        marginBottom: 24,
    },
};
export interface ITab {
    id: ACTIVE_LANDING_PAGE_TAB,
    text:string
}
interface StyledTabsProps {
    value: ACTIVE_LANDING_PAGE_TAB;
    onChange: (event: React.ChangeEvent<{}>, newValue: ACTIVE_LANDING_PAGE_TAB) => void;
}

const StyledTabs = withStyles((theme: Theme) => ({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        minWidth:'inherit',
        '& > span': {
            width: '20px',
            height: '2px',
            objectFit: 'contain',
            borderRadius: '1px',
            backgroundColor: theme.palette.primary.main,
        },
    },
}), { withTheme: true })((props: StyledTabsProps) => <Tabs data-test-id="tab-menu" {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
    label: string;
    value: ACTIVE_LANDING_PAGE_TAB;
}

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth:'inherit',
            padding:'16px 20px 15px 20px',
            textTransform: 'none',
            fontFamily: "'Open Sans',sans-serif",
            fontSize: '14px',
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#3f526d',
            '&:focus': {
                opacity: 1,
                color: theme.palette.primary.main,
            },
            '&:active':{
                opacity: 1,
                color: theme.palette.primary.main,
            }
        },
    }),
)((props:StyledTabProps) => <Tab disableRipple {...props} />);

export interface customChangeEvent {
    event: React.ChangeEvent<{}>
}

type MyProps = {
    classes:any,
    tabs:ITab[],
    handleTabChange:(newValue: ACTIVE_LANDING_PAGE_TAB)=>void,
    activeTab: ACTIVE_LANDING_PAGE_TAB;
}
type MyState = {

}

class TabMenu extends Component<MyProps,MyState> {
    constructor(props:any){
        super(props);

        this.state = {
            activeTab: 'TIMELINE'
        }

    }

    render(){
        const {classes,tabs,handleTabChange,activeTab} = this.props;

        return (
            <>
                <Paper className={classes.root}>
                    <StyledTabs value={activeTab} onChange={(event,x)=>handleTabChange(x)} aria-label="styled tabs example">
                        {
                            tabs.map((item:ITab)=>(
                                <StyledTab key={item.id} label={item.text} value={item.id} />
                            ))
                        }
                    </StyledTabs>
                </Paper>
            </>
        );
    }
}

export default withStyles(classes)(TabMenu);
