import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getToken, getUserInformation } from "../../../components/src/utilities/StorageHelper";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { FormikHelpers } from "formik";
import React from "react";
interface IUser {
  id: string;
  profilePicture: string;
  fullname: string;
  postCount: number;
  totalFilesCount: number;
  totalGoalsCount: number;
  totalImagesCount: number;
  totalVideosCount: number;
}

interface IGrade {
  name: string;
  id: string;
}

interface IDivision {
  name: string;
  id: string;
}

export const configLandingJSON = require("../../landingpage/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  users: IUser[];
  listType:string;
  grade_id: string;
  division_id: string;
  grades: IGrade[];
  divisions: IDivision[];
  school_id?: number;
  searchValue: string;
  token: string;
  loading: '' | 'GRADES' | 'DIVISIONS' | 'STUDENTS';
  totalPagesOfUserList: number;
  activePageNumber: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FriendListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fetchStudentsByDivisionAPICallId: any;
  fetchGradesAPICallId: any;
  fetchDivisionsApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleNavigate = this.handleNavigate.bind(this);
    this.fetchStudentsByDivision = this.fetchStudentsByDivision.bind(this);
    this.handleFetchGradesApiResponse = this.handleFetchGradesApiResponse.bind(this);
    this.handleFetchStudentsByDvisionApiResponse = this.handleFetchStudentsByDvisionApiResponse.bind(this);
    this.handleFetchDivisionsApiResponse = this.handleFetchDivisionsApiResponse.bind(this);
    this.fetchGrades = this.fetchGrades.bind(this);
    this.fetchDivisions = this.fetchDivisions.bind(this);
    this.onGradeSelected = this.onGradeSelected.bind(this);
    this.onDivisionSelect = this.onDivisionSelect.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);

    this.goToProfile = this.goToProfile.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.ProfileWidgetData),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      users: [],
      searchValue: '',
      listType: "",
      grade_id: '',
      division_id: '',
      grades: [],
      divisions: [],
      school_id: getUserInformation()?.school_id,
      token: getToken(),
      loading: '',
      totalPagesOfUserList: 1,
      activePageNumber: 1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseName = getName(MessageEnum.RestAPIResponceMessage)
    const responseDataMessage = getName(MessageEnum.RestAPIResponceDataMessage);
    const isRestApiCallback = responseName === message.id;
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const isFetchStudentsByDivisionResponse = (
      isRestApiCallback
      && this.fetchStudentsByDivisionAPICallId != null
      && this.fetchStudentsByDivisionAPICallId === message.getData(responseDataMessage)
    );

    const isGetGradesResponse = (
      isRestApiCallback
      && this.fetchGradesAPICallId != null
      && this.fetchGradesAPICallId === message.getData(responseDataMessage)
    );

    const isFetchDivisionsResponse = (
      isRestApiCallback
      && this.fetchDivisionsApiCallId != null
      && this.fetchDivisionsApiCallId === message.getData(responseDataMessage)
    );

    if (isFetchStudentsByDivisionResponse || isGetGradesResponse || isFetchDivisionsResponse) {
      this.setState({
        loading: '',
      }, () => {
        try {
          if (isFetchStudentsByDivisionResponse) {
            this.handleFetchStudentsByDvisionApiResponse(message, responseJson);
          } else if (isGetGradesResponse) {
            this.handleFetchGradesApiResponse(message, responseJson);
          } else if (isFetchDivisionsResponse) {
            this.handleFetchDivisionsApiResponse(message, responseJson);
          }
        } catch(err) {
          console.error(err);
        }
      });
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentWillMount() {
    let getListType = await localStorage.getItem('listType')

    if(getListType === "teachers") await this.setState({ listType: "teachers" })
    else await this.setState({ listType: "students" })
  }

  handleFetchGradesApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson?.data)) {
      this.setState({
        grades: responseJson.data.map((item: any) => {
          return {
            id: item.id,
            name: item.attributes.name
          }
        })
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleFetchStudentsByDvisionApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson?.data)) {
      this.setState({
        users: responseJson.data.map((item: any) => ({
          id: item.id,
          profilePicture: item.attributes.avatar,
          fullname: `${item.attributes.first_name} ${item.attributes.last_name}`,
          postCount: 0,
          totalFilesCount: 0,
          totalGoalsCount: 0,
          totalImagesCount: 0,
          totalVideosCount: 0,
        })),
        totalPagesOfUserList: responseJson.meta.pagination.total_pages
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleFetchDivisionsApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson?.data)) {
      this.setState({
        divisions: responseJson.data.map((item: any) => ({
          name: item.name,
          id: item.id.toString()
        }))
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleSearch = (values:{search:string}) => {
    this.setState({
      searchValue: values.search
    });
  };

  handleNavigate(item: IUser) {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");

    const receivedData: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    const userInformationData:any = localStorage.getItem("userInformation")

    if(typeof window !== 'undefined'){
      localStorage.removeItem('profileWidgetData')
      localStorage.removeItem('profileType')
    }

    localStorage.setItem('profileType',"visitingStudent")
    localStorage.setItem('profileWidgetData',JSON.stringify(item))
    
    receivedData.addData(getName(MessageEnum.ProfileWidgetData),item)
    receivedData.addData(getName(MessageEnum.UserInformationData),JSON.parse(userInformationData))
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), receivedData);

    this.send(msg);
    //runEngine.sendMessage(item,msg)
  }

  fetchGrades() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchGradesAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.getGradesApi.baseEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGradesApi.baseEndpoint}/${configJSON.getGradesApi.endpointMethod}?school_id=${this.state.school_id}&token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getGradesApi.contentType)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getGradesApi.apiMethod
    );

    this.setState({
      loading: 'GRADES'
    }, () => {
      runEngine.sendMessage(requestMessage.id, requestMessage);      
    });
  }

  fetchDivisions() {
    const { grade_id, school_id } = this.state;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDivisionsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDivisionsApi.baseEndpoint}/${configJSON.getDivisionsApi.endpointMethod}?grade_ids[]=${grade_id}&school_id=${school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getDivisionsApi.contentType)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDivisionsApi.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchStudentsByDivision() {
    const data = {
      grade_id: parseInt(this.state.grade_id),
      division_id: parseInt(this.state.division_id),
      activePage: this.state.activePageNumber
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchStudentsByDivisionAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentsByDivisionApi.baseEndpoint}/${configJSON.getStudentsByDivisionApi.endpointMethod}?grade_id=${data.grade_id}&division_ids[]=${data.division_id}&page=${data.activePage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getStudentsByDivisionApi.contentType)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getStudentsByDivisionApi.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onGradeSelected(event: React.ChangeEvent<{ value: any }>) {
    const grade_id = event.target.value;

    this.setState({
      grade_id,
      division_id: '',
      users: [],
      loading: 'DIVISIONS'
    }, this.fetchDivisions)
  }

  onDivisionSelect(event: React.ChangeEvent<{ value: any }>) {
    const division_id = event.target.value;

    this.setState({
      division_id,
      loading: 'STUDENTS'
    }, this.fetchStudentsByDivision)
  }

  onPageChanged(event: React.ChangeEvent<unknown>, page: number) {
    this.setState({
      activePageNumber: page
    }, this.fetchStudentsByDivision)
  }

  goToProfile() {
    if(typeof window !== 'undefined'){
      localStorage.removeItem('profileType');
      localStorage.removeItem('profileWidgetData');
      this.props.navigation.navigate("LandingPage");
    }
    localStorage.setItem('profileType','myProfile');
  }

  // Customizable Area End
}
