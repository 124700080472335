import { Avatar, Button, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { withTheme, Theme } from '@material-ui/core/styles';
import React from 'react';
import GenericCard from './GenericCard.web';
import CreateContentFooterSection from './CreateContentFooterSection.web';
import { ProfilePicture } from './assets/images';
import DialogWrapper from './DialogWrapper';
import AddToDialogContent from './AddToDialogContent.web';
import AddFileDialog from './AddFileDialog';
import './assets/styles/CreateContentWidget.css';
import PostImages from './PostImages';

class CreateContentWidget extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.onImagesSelected = this.onImagesSelected.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.onFileButtonClicked = this.onFileButtonClicked.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.state = {
      isDialogOpen: false,
      isFileUploadDialogOpen: false,
      files: [],
      showFiles: false,
      imageUrls: [],
      isInnerDialog: false
    }
  }

  openDialog(view?: IState['view'], isInnerDialog?: boolean) {
    this.setState({
      view,
      isDialogOpen: true,
      isInnerDialog
    });
  }

  closeDialog() {
    this.setState({
      isDialogOpen: false,
      isInnerDialog: false
    })
  }

  onImagesSelected(imageUrls: string[]) {
    this.setState((prevState) => ({
      ...prevState,
      view: 'CREATE_CONTENT',
      imageUrls: [...prevState.imageUrls, ...imageUrls]
    }));
  }

  onFileUploaded() {
    this.setState({
      view: 'CREATE_CONTENT'
    });
  }

  onFileButtonClicked() {
    this.setState({
      isFileUploadDialogOpen: true,
      view: 'UPLOAD_FILE'
    });
  }

  onDrop = (files: any) => {
    this.setState({ files })
  };

  handleDeleteFile = (id: number) => {
    this.setState((state) => ({ files: state.files.filter((item: any, index: number) => index !== id) }))
  }

  handleAddFiles = () => {
    this.setState({
      view: 'CREATE_CONTENT'
    })
  }

  handleCloseModal() {
    this.setState((prevState) => {
      if (prevState.isInnerDialog) {
        return { ...prevState, view: 'CREATE_CONTENT' }
      }

      return { ...prevState, isDialogOpen: false, isInnerDialog: false }
    })
  }

  handleCloseImageCarouselDialog() {
    this.setState({

    })
  }

  render() {
    const { theme, children, title, actionButtonTitle, dialogTitle, useAvatar = true, id = '', inputPlaceholder } = this.props;
    const { imageUrls } = this.state;

    const AvatarDOM = (
      <InputAdornment position="start">
        <Avatar src={ProfilePicture} className="avatar" />
      </InputAdornment>
    );

    return (
      <>
        <GenericCard id="create-content-widget">
          <Typography className="widget-title" component="h6">{title}</Typography>

          <div className="widget-content" id={id}>
            <TextField
              className="textarea"
              placeholder={inputPlaceholder}
              variant="filled"
              fullWidth
              multiline
              InputProps={{
                classes: {
                  root: 'mui-textarea-wrapper'
                },
                disableUnderline: true,
                startAdornment: useAvatar ? AvatarDOM : null
              }}
            />

            {children && (
              <div className="widget-children-wrapper">
                {children}
              </div>
            )}

            <CreateContentFooterSection
              onPhotoOrVideoButtonClicked={() => this.openDialog('IMAGE_OPTIONS')}
              onFileButtonClicked={() => this.openDialog('UPLOAD_FILE')}
              onActionButtonClicked={() => { }}
              actionButtonText={actionButtonTitle}
            />
          </div>
        </GenericCard>

        <DialogWrapper
          isOpen={this.state.isDialogOpen}
          handleCloseModal={this.closeDialog}
        >
          {this.state.view === 'IMAGE_OPTIONS' && (
            <AddToDialogContent
              title={dialogTitle}
              onImagesSelected={this.onImagesSelected}
              isMultipleImageSelection={this.props.isMultipleImageSelection}
            />
          )}

          {this.state.view === 'CREATE_CONTENT' && (
            <div className="create-content-dialog-wrapper">
              <Typography className="dialog-title" component="h6">{title}</Typography>

              <div className="dialog-content">
                <div className="personal-info">
                  <Avatar src={ProfilePicture} className="avatar" />
                  <Typography component="strong">Albert Smith</Typography>
                </div>

                <TextField
                  className="textarea"
                  placeholder="What's new, Albert?"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true
                  }}
                  fullWidth
                  multiline
                />

                {imageUrls.length > 0 && (
                  <PostImages
                    deletable
                    images={imageUrls}
                    /* onImageRemove={this.onImageRemove}
                    onImageClicked={this.onImageClicked} */
                  />
                )}

                <CreateContentFooterSection
                  onPhotoOrVideoButtonClicked={() => this.openDialog('IMAGE_OPTIONS', true)}
                  onFileButtonClicked={() => this.openDialog('UPLOAD_FILE', true)}
                  onActionButtonClicked={() => { }}
                  actionButtonText={actionButtonTitle}
                />
              </div>
            </div>
          )}

          {this.state.view === 'UPLOAD_FILE' && (
            <div className="upload-dialog-wrapper">
              <AddFileDialog
                handleAddFiles={this.onFileUploaded}
                onDrop={this.onDrop}
                handleDeleteFile={this.handleDeleteFile}
                files={this.state.files}
                useWrapper={false}
                handleCloseModal={this.handleCloseModal}
              />
            </div>
          )}
        </DialogWrapper>
      </>
    )
  }
}

interface IProps {
  theme: Theme;
  children?: React.ReactNode
  title: string;
  actionButtonTitle: string;
  dialogTitle: string;
  inputPlaceholder: string;
  useAvatar?: boolean;
  id?: string;
  isMultipleImageSelection?: boolean;
}
interface IState {
  isDialogOpen: boolean;
  isInnerDialog?: boolean;
  view?: 'CREATE_CONTENT' | 'IMAGE_OPTIONS' | 'UPLOAD_FILE';
  isFileUploadDialogOpen: boolean;
  files: any,
  showFiles: boolean;
  imageUrls: string[];
}

export default withTheme(CreateContentWidget);