import React, { Component } from 'react';
import { Grid, Button, Typography, IconButton, Box } from "@material-ui/core";
import { AddCircleOutlineOutlined, CloseOutlined, ArrowBackIosOutlined } from "@material-ui/icons";
import Slider from 'react-slick';
import DialogWrapper from './DialogWrapper';
import AddToDialogContent from './AddToDialogContent.web';
import TargetGroupSelectbox from './TargetGroupSelectbox.web';
import './assets/styles/stories.css';
import './assets/styles/SlickSlider.css';

export interface IStory {
    id: number,
    mainImage: string,
    miniImage: string
}


type MyProps = {
    stories: IStory[];
    storyCreatable?: boolean;
    toggleStoryCreateDialogVisibility?: () => void;
    isStoryCreateModalOpen?: boolean;
    navigation:any;
    profileType:string;
}
type MyState = {
    isStoryImageSelected: boolean;
    storyImages: string[];
}

class Stories extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);

        this.onImagesSelected = this.onImagesSelected.bind(this);
        this.onDialogClose = this.onDialogClose.bind(this);
        this.state = { isStoryImageSelected: false, storyImages: [] }
    }

    onImagesSelected(imageUrls: string[]) {
        this.setState({
            isStoryImageSelected: true,
            storyImages: imageUrls
        });
    }

    onDialogClose() {
        this.setState({ isStoryImageSelected: false }, () => {
            this.props.toggleStoryCreateDialogVisibility!();
        });
    }

    onRemoveImage(idx: number) {
        const storyImages = this.state.storyImages;
        storyImages.splice(idx, 1);

        this.setState({ storyImages });
    }

    render() {
        return (
            <>
                <Grid className="stories-header" container item direction="row" alignItems="center" justifyContent="space-between">
                    <h3>Stories</h3>
                    <Button onClick={()=>this.props.navigation.navigate("UserStatus")} variant="text" color="primary">See All</Button>
                </Grid>
                <Grid className="stories-container" container direction="row" justifyContent="flex-start">
                    {this.props.storyCreatable === true && (
                        <>
                            {this.props.profileType === "myProfile" && (
                                <div className="story-item">
                                    <Button color="primary" className="create-story-button" onClick={this.props.toggleStoryCreateDialogVisibility}>
                                        <AddCircleOutlineOutlined />
                                        <Typography component="span" className="add-to-story-text">Add Story</Typography>
                                    </Button>
                                </div>
                            )}

                            <DialogWrapper
                                isOpen={this.props.isStoryCreateModalOpen!}
                                handleCloseModal={this.onDialogClose}
                                id="story-viewer-dialog"
                            >
                                {this.state.isStoryImageSelected === false && (
                                    <AddToDialogContent
                                        title="Add To Story"
                                        onImagesSelected={this.onImagesSelected}
                                        isMultipleImageSelection
                                    />
                                )}

                                {this.state.isStoryImageSelected === true && (
                                    <div className="share-story-wrapper">
                                        <div className="target-group">
                                            <TargetGroupSelectbox />
                                        </div>

                                        {this.state.storyImages.length > 0 && (
                                            <div className="story-images-wrapper">
                                                <Slider
                                                    dots={false}
                                                    infinite={false}
                                                    slidesToShow={Math.min(3, this.state.storyImages.length)}
                                                    slidesToScroll={1}
                                                    nextArrow={(
                                                        <IconButton className="arrow-button">
                                                            <ArrowBackIosOutlined className="next-arrow-icon" />
                                                        </IconButton>
                                                    )}
                                                    prevArrow={(
                                                        <IconButton className="arrow-button">
                                                            <ArrowBackIosOutlined />
                                                        </IconButton>
                                                    )}
                                                    className="slider-wrapper"
                                                >
                                                    {this.state.storyImages.map((item, idx) => (
                                                        <div key={idx}>
                                                            <Box
                                                                className="carousel-item"
                                                                style={{ backgroundImage: `url(${item})` }}
                                                            >
                                                                <IconButton
                                                                    className="remove-button"
                                                                    onClick={() => this.onRemoveImage(idx)}
                                                                    disabled={this.state.storyImages.length === 1}
                                                                >
                                                                    <CloseOutlined />
                                                                </IconButton>
                                                            </Box>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        )}

                                        <div className="actions">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                onClick={this.onDialogClose}
                                            >
                                                Discard
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                onClick={this.onDialogClose}
                                            >
                                                Share to Story
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </DialogWrapper>
                        </>
                    )}

                    {
                        this.props.stories.map((item: IStory) => (
                            <div className="story-item">
                                <img className="main-image" src={item.mainImage} alt="" />
                                <img className="mini-image" src={item.miniImage} alt="" />
                            </div>
                        ))
                    }
                </Grid>
            </>
        );
    }
}

export default Stories;