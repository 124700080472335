import React from "react";
import { Text, StyleSheet, View, TouchableOpacity } from "react-native";

import Loader from "../../../components/src/Loader";
import SocialMediaAccountWebController, {
  Props,
  configJSON
} from "../../social-media-account/src/SocialMediaAccountWebController";

import CustomFacebookLogInButton from "../../social-media-account/src/CustomFacebookLogInButton";
import CustomGoogleLogInButton from "../../social-media-account/src/CustomGoogleLogInButton";
// Customizable Area Start
import { Field, Form, Formik } from "formik";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Paper
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import SignContainer from "../../../components/src/containers/SignContainer.web";
import "./main.css";
export interface IUser {
  email:string,
  password:string
}
// Customizable Area End

class SocialMediaAccountLoginScreen extends SocialMediaAccountWebController {
  static SocialMediaAccountLoginScreen: SocialMediaAccountLoginScreen;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isRegistration: false
      // Customizable Area Start
      ,userInformation:{email:"",password:""}
      // Customizable Area End
    };
  }

  render() {
    const { loading } = this.state;
    return (
      <View style={styles.container}>
        <Loader loading={loading} />
        {/* Customizable Area Start */}

        <SignContainer>
          <div id="login-page">
            <Paper className="login-card">
              {(this.state.view === "FORM" || !this.state.view) && (
                <div className="form-view">
                  <Formik initialValues={{...this.state.userInformation}} onSubmit={this.submitEmailForm}>
                    {props => (
                      <Form translate="yes" className="login-form">
                        <div className="field-item">
                          <Field
                            name="email"
                            render={({
                              field,
                              form: { isSubmitting }
                            }: {
                              field: any;
                              form: any;
                            }) => (
                              <TextField
                                {...field}
                                disabled={isSubmitting}
                                className="textarea"
                                variant="outlined"
                                label="Email"
                                fullWidth
                              />
                            )}
                          />
                        </div>

                        <div className="field-item">
                          <Field
                            name="password"
                            type="password"
                            render={({
                              field,
                              form: { isSubmitting }
                            }: {
                              field: any;
                              form: any;
                            }) => (
                              <TextField
                                {...field}
                                type={
                                  this.state.showPassword ? "text" : "password"
                                }
                                disabled={isSubmitting}
                                className="textarea"
                                variant="outlined"
                                label="Password"
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          this.setState(prevState => ({
                                            ...prevState,
                                            showPassword: !prevState.showPassword
                                          }));
                                        }}
                                        edge="end"
                                      >
                                        {this.state.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )}
                          />
                        </div>

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="sign-in-button"
                        >
                          Sign In
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}

              {this.state.view === "OTP" && (
                <div className="content-view">
                  <Formik initialValues={{}} onSubmit={this.submitOtpForm}>
                    <Form translate="yes" className="otp-form">
                      <div className="field-item">
                        <Field
                          name="otp_code"
                          render={({
                            field,
                            form: { isSubmitting }
                          }: {
                            field: any;
                            form: any;
                          }) => (
                            <TextField
                              {...field}
                              disabled={isSubmitting}
                              className="textarea"
                              variant="outlined"
                              label="OTP Code"
                              fullWidth
                            />
                          )}
                        />
                      </div>

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="sign-in-button"
                      >
                        Apply
                      </Button>
                    </Form>
                  </Formik>
                </div>
              )}
            </Paper>
          </div>

          {this.state.view === "SOCIAL" && (
            <>
              <Text>
                {configJSON.logInTitleText} {/*UI Engine::From Sketch*/}
              </Text>

              <CustomFacebookLogInButton
                testID="btnFacebookLogIn" //Merge Engine::From BDS
                appId="170982444234877" //Merge Engine::From SDS
                loginFacebookButtonText={configJSON.loginFacebookButtonText} //UI Engine::From Sketch
                {...this.btnFacebookLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              <CustomGoogleLogInButton
                testID="btnGoogleLogIn" //Merge Engine::From BDS
                loginGoogleButtonText={configJSON.loginGoogleButtonText} //UI Engine::From Sketch
                {...this.btnGoogleLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

              <TouchableOpacity
                testID="btnNavigate" //Merge Engine::From BDS
                {...this.btnNavigateProps} //Merge Engine::From BDS - {...this.testIDProps}
              >
                <Text>
                  {configJSON.loginButtonText} {/*UI Engine::From Sketch*/}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </SignContainer>
        {/* Customizable Area Start */}
      </View>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5FCFF"
  }
});
// Customizable Area End

export default SocialMediaAccountLoginScreen;
