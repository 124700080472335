import React from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import GenericCard from './GenericCard.web';
import './assets/styles/NotificationsWidget.css';
import { CloseOutlined } from '@material-ui/icons';
import { NotificationImage } from './assets/images';
import Avatar from './BorderedAvatar.web';

class NotificationsWidget extends React.Component<IProps, IState> {
  render() {
    const { notifications, onAllNotificationsRemove, onNotificationRemove } = this.props;

    return (
      <GenericCard
        id="notifications-widget"
        title="Notifications"
        titleAction={
          notifications.length > 0 ? (
            <Button
              variant="text"
              color="primary"
              className="notifications-clear-all-button"
              onClick={onAllNotificationsRemove}
              data-test-id="notifications-clear-all-button"
            >
              Clear All
            </Button>
          ) : null
        }
      >
        {notifications.length === 0 && (
          <div className="empty-notification-wrapper">
            <img
              src={NotificationImage}
              width={86}
              height={86}
              alt="empty-notifications"
            />
            <Typography component="span">No Notification Right Now!</Typography>
          </div>
        )}

        {notifications.length > 0 && (
          <>
            {notifications.map(notification => (
              <div className="notification-item" key={notification.id}>
                <Avatar
                  className="notification-avatar"
                  src={notification.profileImage}
                />

                <div className="texts">
                  <Typography component="span" className="notification-text">
                    {notification.text}
                  </Typography>
                  <Typography
                    component="small"
                    className="notification-created_at"
                    variant="caption"
                  >
                    {notification.createdAt}
                  </Typography>
                </div>

                <IconButton
                  size="small"
                  className="notification-close-button"
                  data-test-id="notification-close-button"
                  onClick={() => onNotificationRemove(notification)}
                >
                  <CloseOutlined />
                </IconButton>
              </div>
            ))}

            <Button
              onClick={()=>this.props.navigation.navigate("Notifications")}
              variant="contained"
              color="primary"
              className="view-all-button"
              fullWidth
            >
              View All
            </Button>
          </>
        )}
      </GenericCard>
    );
  }
}

export interface INotificationItem {
  id: string | number;
  profileImage: string;
  text: string;
  createdAt: string;
}

interface IProps {
  notifications: INotificationItem[];
  onNotificationRemove: (notification: INotificationItem) => void;
  onAllNotificationsRemove: () => void;
  navigation:any
}

interface IState {}

export default NotificationsWidget;
