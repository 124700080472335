import React from 'react';
import GenericCard from './GenericCard.web';
import 'sassy-datepicker/dist/styles.css';
import './assets/styles/calendarWidget.css';
import DatePicker from "sassy-datepicker";

class CalendarWidget extends React.Component<IProps, IState> {
    render() {
        return (
            <GenericCard id="calendar-widget" title="Calendar">
                <DatePicker
                    value={new Date()}
                    onChange={()=>{}}
                    minDate={new Date()}
                    weekStartsFrom="Monday"
                    className="calendar"
                    translate
                />
            </GenericCard>
        );
    }
}

interface IProps {
}

interface IState {}

export default CalendarWidget;
