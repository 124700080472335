import {Button, Typography} from '@material-ui/core';
import React from 'react';
import GenericCard from './GenericCard.web';
import './assets/styles/chatWidget.css';

class ChatWidget extends React.Component<IProps, IState> {
    render() {
        const { data } = this.props;

        return (
            <GenericCard id="chat-widget" title="Chat">
                {data.map(d => (
                    <Button variant="text" className="chat-item" key={d.id}>
                        <img
                            className="chat-avatar"
                            src={d.profileImage}
                            width={48}
                            height={48}
                            alt="chat-avatar"
                        />
                        <Typography className="chat-title">{d.title}</Typography>
                    </Button>
                ))}
                <Button
                    //onClick={()=>this.props.navigation.navigate("News")}
                    variant="contained"
                    color="primary"
                    className="view-all-button"
                    fullWidth
                >
                    View All
                </Button>
            </GenericCard>
        );
    }
}

export interface IChatWidgetItem {
    id: string | number;
    profileImage: string;
    title: string;
}

interface IProps {
    data: IChatWidgetItem[];
}

interface IState {}

export default ChatWidget;
