import React from 'react';
import {
  Typography
} from '@material-ui/core';
import GenericCard from './GenericCard.web';
import ProfileSummaryView, {
  IProps as IProfileSummaryViewProps
} from './ProfileSummaryView.web';
import './assets/styles/ProfileWidget.css';

class ProfileWidget extends React.Component<IProps, IState> {
  render() {
    const { userInfo, isTeacher } = this.props;

    return (
      <GenericCard id="profile-widget">
        <ProfileSummaryView
          className="profile-widget-item"
          profilePicture={userInfo.profilePicture}
          name={userInfo.name}
          title={userInfo.title}
          isVerifiedUser={userInfo.isVerifiedUser}
          totalImagesCount={userInfo.totalImagesCount}
          totalFilesCount={userInfo.totalFilesCount}
          totalVideosCount={userInfo.totalVideosCount}
          totalGoalsCount={userInfo.totalGoalsCount}
          size={userInfo.size}
          profileType="unset"
        />

        <div className="about-me profile-widget-item">
          <Typography component="h6" className="section-title">
            About Me
          </Typography>

          <Typography variant="caption" component="p" className="section-text">
            {userInfo.biography}
          </Typography>
        </div>
      </GenericCard>
    );
  }
}

interface IProps {
  userInfo: IProfileSummaryViewProps & { biography: string };
  isTeacher: boolean;
}

interface IState {}

export default ProfileWidget;
