import React from 'react';
import { Box, Button, Divider } from '@material-ui/core';
import { FileIcon2, ImageIcon } from './assets/images'
import TargetGroupSelectBox from './TargetGroupSelectbox.web';
import './assets/styles/CreateContentFooterSection.css';

class CreateContentFooterSection extends React.Component<IProps> {
  render() {
    const { actionButtonText = 'Post' } = this.props;

    return (
      <Box id="create-content-footer-section-wrapper">
        <div className="left-side">
          <Button
            className="section-item"
            variant="text"
            startIcon={<img src={ImageIcon} width={20} height={20} />}
            onClick={this.props.onPhotoOrVideoButtonClicked}
          >
            Photo/Video
          </Button>

          <Divider orientation="vertical" flexItem />

          <Button
            className="section-item"
            variant="text"
            startIcon={<img src={FileIcon2} width={20} height={20} />}
            onClick={this.props.onFileButtonClicked}
          >
            File
          </Button>
        </div>

        <div className="right-side">
          <TargetGroupSelectBox size="small" />
          <Button
            className="action-button"
            variant="contained"
            color="primary"
            onClick={this.props.onActionButtonClicked}
          >
            {actionButtonText}
          </Button>
        </div>
      </Box>
    )
  }
}

interface IProps {
  onPhotoOrVideoButtonClicked: () => void,
  onFileButtonClicked: () => void,
  onActionButtonClicked: () => void,
  actionButtonText?: string;
}

export default CreateContentFooterSection;