import React from 'react';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core';
import GenericCard from './GenericCard.web';
import './assets/styles/otherMembers.css';
import { NoMemberImage } from './assets/images';

class OtherMembers extends React.Component<IProps, IState> {

    handleChangeListType = () => {
        localStorage.removeItem('listType')
        let result = ''
        if(this.props.title === 'Other Students'){
            result = 'students'
            console.log(result, "if result")
        }
        else{result = 'teachers';console.log(result,"else result")}
        localStorage.setItem('listType',result)
        this.props.navigation.navigate('FriendList')
    }

    render() {
        const { title,otherUserPictures } = this.props;

        return (
            <GenericCard id="other-members-widget">
                <div className="other-users profile-widget-item">
                    <Typography variant="h6" className="section-title">
                        {title}
                    </Typography>

                    {otherUserPictures.length === 0 && (
                        <div className="empty-members-wrapper">
                            <img
                                src={NoMemberImage}
                                width="auto"
                                height={86}
                                alt="empty-member"
                            />
                            <Typography component="span">No {title} Found!</Typography>
                        </div>
                    )}

                    {otherUserPictures.length > 0 && (
                        <>
                            <Grid
                                className="users-wrapper"
                                container
                                justifyContent="space-between"
                                spacing={1}
                            >
                                {otherUserPictures.map((picture, idx) => (
                                    <IconButton className="user-item" key={idx}>
                                        <Avatar src={picture} className="user-avatar" />
                                    </IconButton>
                                ))}
                            </Grid>
                            <Button
                                onClick={this.handleChangeListType}
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                className="view-all-button"
                            >
                                View All
                            </Button>
                        </>
                    )}

                </div>
            </GenericCard>
        );
    }
}

interface IProps {
    otherUserPictures: string[];
    title:string;
    navigation:any;
}

interface IState {}

export default OtherMembers;
