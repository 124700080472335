import React, {Component} from 'react';
import "./assets/styles/commentBox.css"
import {IComment} from "./CommentsDialog";

type MyProps = {
    item: IComment
}
type MyState = {

}

class CommentBox extends Component<MyProps, MyState> {
    constructor(props:any) {
        super(props);
    }

    render() {
        const {item} = this.props
        return (
            <div key={item.id} className="comment-box">
                <img src={item.image} alt=""/>
                <div className="comment-container">
                    <div className="comment-text-container">
                        <h5>{item.name}</h5>
                        <p>{item.comment}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default CommentBox;
