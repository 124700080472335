import React, {Component} from 'react';
import './assets/styles/postInteractions.css'
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import {Comment, Edit} from "./assets/images";
import {Grid, IconButton, Menu, MenuItem} from "@material-ui/core";
import { withTheme, Theme } from "@material-ui/core/styles";
import ShareOutlined from "@material-ui/icons/ShareOutlined";
import {IInteractions, IPost} from "./SinglePost";
import CommentsDialog from "./CommentsDialog";
import AddAnswerDialog from "./AddAnswerDialog";
import SendInChatDialog from "./SendInChatDialog";
import ShareToFeedDialog from "./ShareToFeedDialog";
import { ACTIVE_LANDING_PAGE_TAB, ACTIVE_LANDING_PAGE_BOXED_VIEW } from "./types"

type MyProps = {
    item:IPost,
    isFavorite: boolean,
    interactions: IInteractions,
    boxedView?: ACTIVE_LANDING_PAGE_BOXED_VIEW,
    answerAction?: "expand" | "dialog",
    question?:string,
    activeTab: ACTIVE_LANDING_PAGE_TAB,
    handleToggleShowComments: () => void
    handleToggleShowReplies: () => void,
    theme: Theme
}
type MyState = {
    isFavorite:boolean,
    isCommentsDialogOpen: boolean,
    isAddAnswerDialogOpen: boolean,
    isSendInChatDialogOpen: boolean,
    isShareToFeedDialogOpen: boolean,
    anchorElShare: null | HTMLElement
}

class PostInteractions extends Component<MyProps,MyState> {
    constructor(props:any){
        super(props);
        this.state = {
            isFavorite:props.isFavorite,
            isCommentsDialogOpen: false,
            isAddAnswerDialogOpen: false,
            isSendInChatDialogOpen: false,
            isShareToFeedDialogOpen: false,
            anchorElShare: null
        }
    }

    handleFavorite = () => {
        this.setState((state) => ({isFavorite : !state.isFavorite}))
    }

    handleOpenShareOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState(({anchorElShare:event.currentTarget}))
    };

    handleCloseShareOptions = () => {
        this.setState(({anchorElShare:null}))
    };

    handleOpenCommentsDialog = () => {
        const { interactions } = this.props;

        if(interactions && interactions.comments && interactions.comments.length > 0){
            this.setState(({isCommentsDialogOpen:true}))
        }
    };

    handleCloseCommentsDialog = () => {
        this.setState(({isCommentsDialogOpen:false}))
    };

    handleOpenAddAnswerDialog = () => {
        this.setState(({isAddAnswerDialogOpen:true}))
    };

    handleCloseAddAnswerDialog = () => {
        this.setState(({isAddAnswerDialogOpen:false}))
    };

    handleOpenSendInChatDialog = () => {
        this.setState(({isSendInChatDialogOpen:true}))
        this.handleCloseShareOptions()
    };

    handleCloseSendInChatDialog = () => {
        this.setState(({isSendInChatDialogOpen:false}))
    };

    handleOpenShareToFeedDialog = () => {
        this.setState(({isShareToFeedDialogOpen:true}))
        this.handleCloseShareOptions()
    };

    handleCloseShareToFeedDialog = () => {
        this.setState(({isShareToFeedDialogOpen:false}))
    };

    render(){
        const { isFavorite, isCommentsDialogOpen, isAddAnswerDialogOpen, isSendInChatDialogOpen, isShareToFeedDialogOpen, anchorElShare } = this.state
        const { interactions, boxedView, answerAction, question, item, activeTab, handleToggleShowComments, handleToggleShowReplies, theme } = this.props

        return (
            <Grid className="post-interactions" container item direction="row" wrap="nowrap">
                <div onClick={this.handleFavorite}>
                {
                    isFavorite ? <Favorite htmlColor={theme.palette.primary.main}/> : 
                    <FavoriteBorder htmlColor="#3f526d"/>
                }
                    
                    <span style={{color:`${isFavorite ? theme.palette.primary.main : "#3f526d"}`}}>{interactions.likes} {boxedView !== 'BOX' && "Likes"}</span>
                </div>
                <div onClick={answerAction === "dialog" ? this.handleOpenAddAnswerDialog : ((activeTab === "FILES" || activeTab === "BADGES") ? this.handleOpenCommentsDialog : (activeTab === "DISCUSSION" ? handleToggleShowReplies : handleToggleShowComments))}>
                    <img src={Comment} alt=""/>
                    <span>{interactions?.comments?.length} {(boxedView !== 'BOX') && (answerAction ? "Answer" : "Comments")}</span>
                </div>
                <IconButton className="share-button" disableRipple onClick={this.handleOpenShareOptions}>
                    <ShareOutlined htmlColor="#3f526d"/>
                    <span>{interactions.shares} {boxedView !== 'BOX' && "Share"}</span>
                </IconButton>
                <Menu
                    id="share-options"
                    anchorEl={anchorElShare}
                    keepMounted
                    open={Boolean(anchorElShare)}
                    onClose={this.handleCloseShareOptions}
                    className="menu-list"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 90,
                        horizontal: boxedView === 'BOX' ? 40 : 80,
                    }}

                >
                    <MenuItem onClick={this.handleOpenSendInChatDialog}>
                        <img src={Comment} alt=""/> Send in Chat
                    </MenuItem>
                    <MenuItem onClick={this.handleOpenShareToFeedDialog}>
                        <img src={Edit} alt=""/> Share to Feed
                    </MenuItem>
                </Menu>
                <CommentsDialog isOpen={isCommentsDialogOpen} handleCloseModal={this.handleCloseCommentsDialog}/>
                <AddAnswerDialog question={question} isOpen={isAddAnswerDialogOpen} handleCloseModal={this.handleCloseAddAnswerDialog}/>
                <SendInChatDialog item={item} isOpen={isSendInChatDialogOpen} handleCloseModal={this.handleCloseSendInChatDialog} />
                <ShareToFeedDialog item={item} isOpen={isShareToFeedDialogOpen} handleCloseModal={this.handleCloseShareToFeedDialog}/>
            </Grid>
        );
    }
}

export default withTheme(PostInteractions);
