import React, {Component} from 'react';
import SinglePost, { IPost } from './SinglePost';
import { IProps as IProfileSummaryViewProps } from "./ProfileSummaryView.web";
import { ACTIVE_LANDING_PAGE_BOXED_VIEW, ACTIVE_LANDING_PAGE_TAB } from "./types";
import {
    Grid
  } from "@material-ui/core";
import CreateContentWidgetWeb from './CreateContentWidget.web';

type MyProps = {
    posts: IPost[];
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    user:Omit<IProfileSummaryViewProps, "size"> & { biography: string };
    boxedView:ACTIVE_LANDING_PAGE_BOXED_VIEW;
    handleHidePost:(id:number)=>void;
}
type MyState = {}

class BadgesTab extends Component<MyProps,MyState> {
    render(){
        if(this.props.activeTab === 'BADGES') {
            return(
                <>
                    {this.props.user.profileType === "myProfile" && (
                        <CreateContentWidgetWeb
                            title="Add Badge"
                            dialogTitle="Add To Badge"
                            actionButtonTitle="Add Badge"
                            useAvatar={false}
                            id="add-to-badge"
                            inputPlaceholder="Badge name here..."
                            isMultipleImageSelection
                        />
                    )}
                    <Grid container item spacing={2}>
                        {this.props.posts.map((item: IPost) => (
                            <SinglePost
                                hidePost={this.props.handleHidePost}
                                activeTab={this.props.activeTab}
                                boxedView={this.props.boxedView}
                                createPost
                                key={item.id}
                                item={item}
                            />
                        ))}
                    </Grid>
                </>
            )
        }
        else return <></>
    }
}

export default BadgesTab
