// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomPageCreator4 from "../../blocks/CustomPageCreator4/src/CustomPageCreator4";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ContentFlag from "../../blocks/ContentFlag/src/ContentFlag";
import LanguageDetection from "../../blocks/LanguageDetection/src/LanguageDetection";
import BlogPostsManagement from "../../blocks/BlogPostsManagement/src/BlogPostsManagement";
import PinConversation from "../../blocks/PinConversation/src/PinConversation";
import Mentionstagging from "../../blocks/Mentionstagging/src/Mentionstagging";
import BlogImporting from "../../blocks/BlogImporting/src/BlogImporting";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import AudioCall from "../../blocks/AudioCall/src/AudioCall";
import Videos from "../../blocks/videos/src/Videos";
import FriendList from "../../blocks/FriendList/src/FriendList";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import Notifications from "../../blocks/notifications/src/Notifications";
import ContentModeration from "../../blocks/ContentModeration/src/ContentModeration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Avatar from "../../blocks/avatar/src/Avatar";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import ManageBlogComments from "../../blocks/ManageBlogComments/src/ManageBlogComments";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import AddFriends from "../../blocks/AddFriends/src/AddFriends";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import GroupVideoCall2 from "../../blocks/GroupVideoCall2/src/GroupVideoCall2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import LiveChatSummary2 from "../../blocks/LiveChatSummary2/src/LiveChatSummary2";
import LocalinternationalShipping from "../../blocks/LocalinternationalShipping/src/LocalinternationalShipping";
import Wiki2 from "../../blocks/Wiki2/src/Wiki2";
import TabletSupport69 from "../../blocks/TabletSupport69/src/TabletSupport69";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import CreateComment from "../../blocks/comments/src/CreateComment";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
CustomPageCreator4:{
 component:CustomPageCreator4,
path:"/CustomPageCreator4"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ContentFlag:{
 component:ContentFlag,
path:"/ContentFlag"},
LanguageDetection:{
 component:LanguageDetection,
path:"/LanguageDetection"},
BlogPostsManagement:{
 component:BlogPostsManagement,
path:"/BlogPostsManagement"},
PinConversation:{
 component:PinConversation,
path:"/PinConversation"},
Mentionstagging:{
 component:Mentionstagging,
path:"/Mentionstagging"},
BlogImporting:{
 component:BlogImporting,
path:"/BlogImporting"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
AudioCall:{
 component:AudioCall,
path:"/AudioCall"},
Videos:{
 component:Videos,
path:"/Videos"},
FriendList:{
 component:FriendList,
path:"/FriendList"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ContentModeration:{
 component:ContentModeration,
path:"/ContentModeration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Avatar:{
 component:Avatar,
path:"/Avatar"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
UserGroups2:{
 component:UserGroups2,
path:"/UserGroups2"},
ManageBlogComments:{
 component:ManageBlogComments,
path:"/ManageBlogComments"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
AddFriends:{
 component:AddFriends,
path:"/AddFriends"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Share:{
 component:Share,
path:"/Share"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
GroupVideoCall2:{
 component:GroupVideoCall2,
path:"/GroupVideoCall2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
LiveChatSummary2:{
 component:LiveChatSummary2,
path:"/LiveChatSummary2"},
LocalinternationalShipping:{
 component:LocalinternationalShipping,
path:"/LocalinternationalShipping"},
Wiki2:{
 component:Wiki2,
path:"/Wiki2"},
TabletSupport69:{
 component:TabletSupport69,
path:"/TabletSupport69"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;