import React from 'react';
import { Button } from '@material-ui/core';
import './assets/styles/CapturePhoto.css';

class CapturePhoto extends React.Component<IProps, IState> {
  private videoRef = React.createRef<HTMLVideoElement>();
  private canvasRef = React.createRef<HTMLCanvasElement>();

  constructor(props: IProps) {
    super(props);

    this.state = { videoStreamObj: null, webcamErrorMessage: '' }
    this.takePicture = this.takePicture.bind(this);
  }

  componentDidMount(): void {
    if (typeof navigator.mediaDevices?.getUserMedia !== 'function') {
      this.setState({
        webcamErrorMessage: 'Unsupported browser'
      });
      return;
    }

    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false
    })
    .then((stream) => {
      if (!this.videoRef.current) { return; }
      this.videoRef.current.srcObject = stream;
      this.videoRef.current.play();
    })
    .catch((err) => {
      this.setState({ webcamErrorMessage: err.message })
    });
  }

  takePicture() {
    const canvas = this.canvasRef.current;
    if (!canvas || !this.videoRef.current) { return; }
    const context = canvas.getContext('2d');
    if (!context) { return; }

    const { width, height } = getComputedStyle(this.videoRef.current);

    if (width && height) {
        canvas.width = 300;
        canvas.height = 300;
        context.drawImage(this.videoRef.current, 0, 0, 300, 300);

        const data = canvas.toDataURL('image/png');
        this.props.onPhotoCaptured(data);
    } else {
        this.clearPhoto();
    }
  }

  clearPhoto() {
    const canvas = this.canvasRef.current;
    if (!canvas) { return; }

    const context = canvas.getContext('2d');
    if (!context) { return; }

    context.fillStyle = '#AAA';
    context.fillRect(0, 0, canvas.width, canvas.height);

    const data = canvas.toDataURL('image/png');
    this.props.onPhotoCaptured(data);
  }

  render() {
    return (
      <div id="capture-photo-view">
        <div className="camera">
          {!!this.state.webcamErrorMessage ? (
            <p>{this.state.webcamErrorMessage}</p>
          ) : (
            <video ref={this.videoRef} id="video">
            Video stream not available.
          </video>
          )}
        </div>

        <Button
          variant="contained"
          color="primary"
          className="take-photo-button"
          disabled={!!this.state.webcamErrorMessage}
          onClick={this.takePicture}
        >
          Take Photo
        </Button>

        <canvas
          id="canvas"
          ref={this.canvasRef}
        />
      </div>
    )
  }
}

interface IProps {
  onPhotoCaptured: (imageUrl: string) => void
}

interface IState {
  videoStreamObj: MediaStream | null;
  webcamErrorMessage: string;
}

export default CapturePhoto;