import React, { Component } from "react";
import { IPost } from "./SinglePost";
import {
    Link
} from "@material-ui/core";

type MyProps = {
    item:IPost,
    handleOpenAnswerView?:(item:IPost)=>void
}
type MyState = {}

class SinglePostContentDescription extends Component<MyProps,MyState>{
    render(){
      const {item,handleOpenAnswerView} = this.props
      return(
        <div className="post-description">
            <p className={`${item?.goalText && "goal-text"} ${item?.video && "video-text"} box-view-description`}>
                {item?.description}
                {item?.goalText}
            </p>
            {
                item?.answers &&
                <p data-test-id="open-answer-view" onClick={()=>handleOpenAnswerView && handleOpenAnswerView(item)} className="answer-text">{item?.answers > 0 ? item?.answers + " answers" : "No answers yet"}</p>
            }
            <div>
                {
                    (item.video || item.link) &&
                    <Link
                        className="post-link"
                        component="button"
                        variant="body2"
                        onClick={() => window.open(`${item?.video?.link || item?.link?.text}`, '_blank')}
                    >
                        {item?.video?.link || item?.link?.text}
                    </Link>
                }
            </div>
        </div>
      )
    }
}

export default SinglePostContentDescription