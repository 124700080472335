
export const hexToRgba = (hex: string): number[] => {
    let num = parseInt(hex.slice(1), 16); // Convert to a number
    return [num >> 16 & 255, num >> 8 & 255, num & 255, num >> 24 & 255];
}

export const getHexAsRgba = (hex: string, alpha: number): string => {
    const rgba = hexToRgba(hex);
    return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${alpha})`;              
}

export const classNames = (classes: { [key in string]: boolean }) => {
    let className = '';
    
    Object.entries(classes).forEach(([key, value]) => {
        if (value === true) {
            className += `${key} `;
        }
    });

    return className;
}

export const safeJSONparse = <T>(str: string, defValue: any = {}): T => {
    let result = null;

    try {
        result = JSON.parse(str);
    } catch(err) {}

    return result || defValue;
}