Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const Man1Photo = require('../assets/man1.png');
const Man2Photo = require('../assets/man2.png');
const Man3Photo = require('../assets/man3.png');
const Man4Photo = require('../assets/man4.png');
const Man5Photo = require('../assets/man5.png');
const Woman1Photo = require('../assets/woman1.png');
const Banner01 = require('../assets/banner01.png');
const Story01 = require('../assets/story-01.png');

exports.userStatusTitle = "User Status List";
exports.userStatusApiMethod = "GET";
exports.userStatusApiContentType = "application/json";
exports.userStatusApiEndpont = "user_status/user_status";
exports.usersOfStoriesDummyData = [
  { id: Math.random(), profilePicture: Man1Photo, fullname: 'Andrew Smith', createdAt: new Date(Date.now() - (60 * 60 * 2000)).toString(), stories: [{ id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man2Photo, fullname: 'William John', createdAt: new Date(Date.now() - (60 * 60 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man3Photo, fullname: 'John Doe', createdAt: new Date(Date.now() - (60 * 40 * 500)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Banner01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Banner01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Banner01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Woman1Photo, fullname: 'Emily Smith', createdAt: new Date(Date.now() - (60 * 40 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man4Photo, fullname: 'Andrew Smith', createdAt: new Date(Date.now() - (60 * 60 * 2000)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man1Photo, fullname: 'Oldrich V.', createdAt: new Date(Date.now() - (60 * 60 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man3Photo, fullname: 'Alex George', createdAt: new Date(Date.now() - (60 * 40 * 500)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Woman1Photo, fullname: 'Alexandra Smith', createdAt: new Date(Date.now() - (60 * 40 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man4Photo, fullname: 'Oldrich V.', createdAt: new Date(Date.now() - (60 * 60 * 2000)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man5Photo, fullname: 'William John', createdAt: new Date(Date.now() - (60 * 60 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man3Photo, fullname: 'Alexandra Smith', createdAt: new Date(Date.now() - (60 * 40 * 500)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Woman1Photo, fullname: 'Emily Smith', createdAt: new Date(Date.now() - (60 * 40 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man4Photo, fullname: 'Andrew Smith', createdAt: new Date(Date.now() - (60 * 20 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man5Photo, fullname: 'William John', createdAt: new Date(Date.now() - (60 * 60 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Man2Photo, fullname: 'John Doe', createdAt: new Date(Date.now() - (60 * 40 * 500)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
  { id: Math.random(), profilePicture: Woman1Photo, fullname: 'Eva Simkova', createdAt: new Date(Date.now() - (60 * 40 * 1000)).toString(), stories: [{ id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }, { id: Math.random(), source: Story01 }] },
];
// Customizable Area End