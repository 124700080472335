import React, {Component} from 'react';
import CreateContentWidget from './CreateContentWidget.web'
import SinglePost, { IPost } from './SinglePost';
import Stories, { IStory } from './Stories';
import { IProps as IProfileSummaryViewProps } from "./ProfileSummaryView.web";
import { ACTIVE_LANDING_PAGE_TAB } from "./types";

type MyProps = {
    posts: IPost[];
    user:Omit<IProfileSummaryViewProps, "size"> & { biography: string };
    stories:IStory[];
    toggleStoryCreateModalVisibility:()=>void;
    isStoryCreateModalOpen:boolean;
    navigation:any;
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    handleHidePost:(id:number)=>void;
}
type MyState = {}

class TimelineTab extends Component<MyProps,MyState> {
    render(){
        if(this.props.activeTab === 'TIMELINE') {
            return(
                <>
                    <Stories
                        data-test-id="stories"
                        profileType={this.props.user.profileType}
                        navigation={this.props.navigation}
                        stories={this.props.stories}
                        storyCreatable
                        toggleStoryCreateDialogVisibility={
                        this.props.toggleStoryCreateModalVisibility
                        }
                        isStoryCreateModalOpen={this.props.isStoryCreateModalOpen}
                    />
    
                    {
                        this.props.user.profileType === "myProfile" &&
                        <CreateContentWidget
                            title="Create Post"
                            actionButtonTitle="Post"
                            dialogTitle="Add To Post"
                            inputPlaceholder="What's new, Albert?"
                            isMultipleImageSelection
                        />
                    }
    
                    {this.props.posts.map((item: IPost) => (
                        <SinglePost
                            hidePost={this.props.handleHidePost}
                            activeTab={this.props.activeTab}
                            createPost
                            key={item.id}
                            item={item}
                        />
                    ))}
                </>
            )
        }
        else return <></>
    }
}

export default TimelineTab
