import React, {Component} from 'react';
import {
    Grid
  } from "@material-ui/core";
import ContactsWidget, { IContactWidgetItem } from './ContactsWidget.web';
import EventsWidget, { IEventWidgetItem } from './EventsWidget.web';
import NotificationsWidget, { INotificationItem } from './NotificationsWidget.web';
import OtherStudentGoalsWidget, { IStudentGoalItem } from './OtherStudentGoalsWidget.web';
import { ACTIVE_LANDING_PAGE_TAB } from "./types";
import CalendarWidget from './CalendarWidget';
import NewsWidget, { INewsData } from './NewsWidget';

type MyProps = {
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    activeWidgets:string[];
    profileStudentGoals:IStudentGoalItem[];
    profileNotifications:INotificationItem[];
    removeNotificationItem:(notification:INotificationItem)=>void;
    removeAllNotificationItems:()=>void;
    profileEventItems:IEventWidgetItem[];
    removeEventItem:(event: IEventWidgetItem)=>void;
    profileContactUsers:IContactWidgetItem[];
    blogs:INewsData[];
    navigation:any;
}
type MyState = {}

class RightWidgets extends Component<MyProps,MyState> {
    render(){
        if(["TIMELINE", "GOALS", "VIDEOS"].includes(this.props.activeTab) &&
        (["Goals","Notifications","Events","Contacts","Calendar","Blogs"].some(r=>this.props.activeWidgets.includes(r)))) {
            return(
                <Grid item className="right-side">
                    <Grid
                        container
                        style={{ flexDirection: "column" }}
                        spacing={4}
                    >

                        {this.props.activeWidgets.includes("Notifications") && (
                            <Grid item>
                                <NotificationsWidget
                                    navigation={this.props.navigation}
                                    notifications={this.props.profileNotifications}
                                    onNotificationRemove={this.props.removeNotificationItem}
                                    onAllNotificationsRemove={
                                        this.props.removeAllNotificationItems
                                    }
                                />
                            </Grid>
                        )}

                        {this.props.activeWidgets.includes("Events") && (
                            <Grid item>
                                <EventsWidget
                                events={this.props.profileEventItems}
                                onEventItemRemove={this.props.removeEventItem}
                                />
                            </Grid>
                        )}

                        {this.props.activeWidgets.includes("Contacts") && (
                            <Grid item>
                                <ContactsWidget
                                    contacts={this.props.profileContactUsers}
                                />
                            </Grid>
                        )}

                        {this.props.activeWidgets.includes("Calendar") && (
                            <Grid item>
                                <CalendarWidget/>
                            </Grid>
                        )}

                        {this.props.activeWidgets.includes("Blogs") && (
                            <Grid item>
                                <NewsWidget
                                    data={this.props.blogs}
                                    title="Blogs"
                                />
                            </Grid>
                        )}
                  </Grid>
                </Grid>
            )
        }
        else return <></>
    }
}

export default RightWidgets
