import React from "react";
// Customizable Area Start
import {
  Button,
  Grid,
  CssBaseline,
  Menu,
  Checkbox,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
import TabMenu from "../../../components/src/TabMenu";
import AddFileDialog from "../../../components/src/AddFileDialog";
import MainContainer from "../../../components/src/containers/MainContainer.web";
import PageHeader from "../../../components/src/PageHeader.web";
import PortfolioDialog from "../../../components/src/PortfolioDialog";
import BoxViewToggle from "../../../components/src/BoxViewToggle";
import DiscussionFilterBy from "../../../components/src/DiscussionFilterBy";
import TimelineTab from "../../../components/src/TimelineTab";
import GoalsTab from "../../../components/src/GoalsTab";
import VideosTab from "../../../components/src/VideosTab";
import FilesTab from "../../../components/src/FilesTab";
import BadgesTab from "../../../components/src/BadgesTab";
import DiscussionForumTab from "../../../components/src/DiscussionForumTab";
import RightWidgets from "../../../components/src/RightWidgets";
import LeftWidgets from "../../../components/src/LeftWidgets";
import { BackIcon } from "./assets";
import { Pdf } from "./assets";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "../../../components/src/assets/styles/normalize.css";
import "../../../components/src/assets/styles/index.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// temporarily. Will be remove future
import "./main.css";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";
import WidgetMenuButton from "../../../components/src/WidgetMenuButton";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    //console.log(configJSON); // I added this because of the eslint issue. Should be remove later.
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <CssBaseline />
        <MainContainer className="landing-page-main-container">
          <PageHeader
            navigation={this.props.navigation}
            pageTitle={
              this.state.user.name ?? "My Profile"
            }
          >
            <WidgetMenuButton
              handleOpenWidgetMenu={event =>
                this.handleOpenWidgetMenu(event as React.MouseEvent<
                  HTMLButtonElement
                >)}
              anchorElWidgetMenu={this.state.anchorElWidgetMenu}
              handleCloseWidgetMenu={this.handleCloseWidgetMenu}
              widgets={this.state.widgets}
              checkedWidgets={this.state.checkedWidgets}
              handleCheckWidgets={this.handleCheckWidgets}
              handleSaveWidgetSettings={this.handleSaveWidgetSettings}
              handleCancelWidgetSettings={this.handleCancelWidgetSettings}
            />
            <Button
              data-test-id="portfolio-dialog-button"
              onClick={this.handleOpenPortfolioDialog}
              variant="outlined"
              color="primary"
              className="pdf-button"
            >
              <img src={Pdf} alt="" width={22} height={24} />
            </Button>
          </PageHeader>

          <Grid container id="landing-page-container">
            <LeftWidgets
              navigation={this.props.navigation}
              activeTab={this.state.activeTab}
              activeWidgets={this.state.activeWidgets}
              user={this.state.user}
              news={this.state.news}
              chatData={this.state.chatData}
              userType={this.state.userType}
              profileOtherUserPictures={this.state.profileOtherUserPictures}
            />
            <Grid
              item
              className={`content-side ${(['FILES','BADGES','DISCUSSION'].includes(this.state.activeTab) ||
                ((['TIMELINE','GOALS','VIDEOS'].includes(this.state.activeTab)) &&
                !["Contacts","Goals","Events","Notifications","Calendar","Blogs"].some(r => this.state.activeWidgets.includes(r)))) &&
                "content-rightSide-fullWidth"} 
                ${!["Profile","News","Friends","Other(Students)","Other(Teachers)","Chat"].some(r => this.state.activeWidgets.includes(r)) &&
                  "content-leftSide-fullWidth"}`}
            >
              <Grid item>
                <div
                  className={`tab-menu box-view ${this.state.isAnswerView &&
                    "answer-view"}`}
                >
                  {this.state.activeTab === "DISCUSSION" &&
                    this.state.isAnswerView && (
                      <Button
                        data-test-id="close-answer-view"
                        className="back-button"
                        variant="contained"
                        color="primary"
                        onClick={this.handleCloseAnswerView}
                        startIcon={<img src={BackIcon} alt="" />}
                      >
                        Back
                      </Button>
                    )}
                  <TabMenu
                    activeTab={this.state.activeTab}
                    handleTabChange={this.handleTabChange}
                    tabs={this.state.tabs}
                  />
                  {!["NONE", "BADGE", "QUESTION"].includes(
                    this.state.boxedView
                  ) && (
                    <BoxViewToggle
                      boxedView={this.state.boxedView}
                      handleBoxViewChange={this.handleBoxViewChange}
                    />
                  )}
                  {this.state.boxedView === "QUESTION" && (
                    <DiscussionFilterBy
                      handleOpenFilter={event =>
                        this.handleOpenFilter(event as React.MouseEvent<
                          HTMLButtonElement
                        >)
                      }
                      anchorElFilter={this.state.anchorElFilter}
                      dummyData={configJSON.subjectBoxes}
                      handleCancelFilter={this.handleCancelFilter}
                      handleClearFilter={this.handleClearFilter}
                      handleCloseFilter={this.handleCloseFilter}
                      handleSortedByChange={this.handleSortedByChange}
                      sortedBy={this.state.sortedBy}
                    />
                  )}
                </div>
                <TimelineTab
                  posts={this.state.posts}
                  user={this.state.user}
                  stories={this.state.stories}
                  toggleStoryCreateModalVisibility={this.toggleStoryCreateModalVisibility}
                  isStoryCreateModalOpen={this.state.isStoryCreateModalOpen}
                  navigation={this.props.navigation}
                  activeTab={this.state.activeTab}
                  handleHidePost={this.handleHidePost}
                />
                <GoalsTab
                  posts={this.state.studentGoals}
                  user={this.state.user}
                  activeTab={this.state.activeTab}
                  handleHidePost={this.handleHidePost}
                />
                <VideosTab
                  posts={this.state.videos}
                  activeTab={this.state.activeTab}
                  handleHidePost={this.handleHidePost}
                />
                
                <FilesTab
                  posts={this.state.files}
                  activeTab={this.state.activeTab}
                  boxedView={this.state.boxedView}
                  handleHidePost={this.handleHidePost}
                />

                <BadgesTab
                  posts={this.state.studentBadges}
                  user={this.state.user}
                  activeTab={this.state.activeTab}
                  boxedView={this.state.boxedView}
                  handleHidePost={this.handleHidePost}
                />
                <DiscussionForumTab
                  activeTab={this.state.activeTab}
                  user={this.state.user}
                  boxedView={this.state.boxedView}
                  questions={this.state.questions}
                  studentAnswers={this.state.studentAnswers}
                  handleHidePost={this.handleHidePost}
                  handleOpenAnswerView={this.handleOpenAnswerView}
                  isAnswerView={this.state.isAnswerView}
                />
              </Grid>
            </Grid>

            <RightWidgets
              activeTab={this.state.activeTab}
              activeWidgets={this.state.activeWidgets}
              profileStudentGoals={this.state.profileStudentGoals}
              profileNotifications={this.state.profileNotifications}
              removeNotificationItem={this.removeNotificationItem}
              removeAllNotificationItems={this.removeAllNotificationItems}
              profileEventItems={this.state.profileEventItems}
              removeEventItem={this.removeEventItem}
              profileContactUsers={this.state.profileContactUsers}
              blogs={this.state.blogs}
              navigation={this.props.navigation}
              data-test-id="right-widgets"
            />

          </Grid>

          <PortfolioDialog
            isOpen={this.state.isPortfolioDialogOpen}
            handleCloseModal={this.handleClosePortfolioDialog}
          />

          <AddFileDialog
            handleAddFiles={this.handleAddFiles}
            onDrop={this.onDrop}
            handleDeleteFile={this.handleDeleteFile}
            files={this.state.uploadedFiles}
            isOpen={this.state.isOpenFileModalOpen}
            handleCloseModal={this.handleCloseAddFileDialog}
          />
        </MainContainer>
      </>
    );
  }
}

// Customizable Area Start
// Customizable Area End
