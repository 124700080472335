import React from 'react';
import { getHexAsRgba } from '../../utilities/Utils'

const FilterIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 24, height = 24, color } = props;

    return(
        <svg
            version="1.1" 
            id="Layer_1" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
            viewBox="0 0 512 512"
            width={width}
            height={height}
            xmlSpace="preserve"
        >
            <g>
                <path style={{ fill: getHexAsRgba(color, 0.6 ) }} d="M256,89.72H15.99V11.15C15.99,4.99,20.98,0,27.14,0H256V89.72z"/>
                <polygon style={{ fill: getHexAsRgba(color, 0.8 ) }} points="15.99,89.72 195.79,271.31 256,271.31 256,89.72 	"/>
                <path style={{ fill: getHexAsRgba(color, 0.6 ) }} d="M256,485l-36.27,24.36c-10.21,6.86-23.94-0.46-23.94-12.76v-225.3H256V485z"/>
                <path style={{ fill: getHexAsRgba(color, 0.8 ) }} d="M256,89.72h240.01V11.15c0-6.16-4.99-11.15-11.15-11.15L256,0V89.72z"/>
                <polygon style={{ fill: color }} points="496.01,89.72 316.21,271.31 256,271.31 256,89.72 	"/>
                <path style={{ fill: getHexAsRgba(color, 0.8 ) }} d="M256,485l56.09-37.04c2.57-1.7,4.12-4.57,4.12-7.66V271.31H256V485z"/>
            </g>
        </svg>
    )
}

export default FilterIcon;