import React, { Component } from 'react';
import "./assets/styles/commentsDialog.css"
import DialogWrapper from "./DialogWrapper";
import CreatePostBox from "./CreatePostBox";
import CommentBox from "./CommentBox";
import { configJSON } from '../../blocks/landingpage/src/LandingPageController';


export interface IComment {
    id:number,
    image:string,
    name:string,
    comment:string
}

type MyProps = {
    isOpen:boolean,
    handleCloseModal: () => void,
}
type MyState = {
    currentComments : IComment[]
}

class CommentsDialog extends Component<MyProps, MyState> {
    constructor(props:any) {
        super(props);
        this.state = {
            currentComments : configJSON.comments
        }
    }
    handleAddComment = (comment:IComment) => {
        this.setState((state)=> ({currentComments: [...state.currentComments, comment]}))
    }
    render() {
        const {isOpen,handleCloseModal} = this.props
        const {currentComments} = this.state
        return (
            <DialogWrapper isOpen={isOpen} handleCloseModal={handleCloseModal}>
                <div className="comments-dialog-body">
                    <h3 className="comments-dialog-title">All Comments</h3>
                    {
                        currentComments?.map((item:IComment)=>(
                            <CommentBox item={item} />
                        ))
                    }
                </div>
                <div className="comment-box-divider"></div>
                <div className="comments-dialog-create-box-container">
                    <CreatePostBox handleAddComment={this.handleAddComment} />
                </div>
            </DialogWrapper>
        )
    }
}

export default CommentsDialog;
