import React, {Component} from 'react';
import {Button} from "@material-ui/core";
import "./assets/styles/addFileDialog.css"
import DialogWrapper from "./DialogWrapper";
import Dropzone from "react-dropzone";
import FileListItem from './FileListItem';

type MyProps = {
    isOpen?:boolean,
    handleCloseModal?: () => void;
    useWrapper?: boolean;
    files:any,
    onDrop:(files:any)=>void,
    handleDeleteFile:(index:number)=>void,
    handleAddFiles: ()=>void
}
type MyState = {}

class AddFileDialog extends Component<MyProps, MyState> {
    constructor(props:any) {
        super(props);
    }
    render() {
        const { isOpen = false, handleCloseModal, files,onDrop,handleDeleteFile,handleAddFiles, useWrapper = true} = this.props

        const ContentDOM = (
            <div className="add-file-dialog-body">
                <h3 className="add-file-dialog-title">Upload File</h3>
                <Dropzone onDrop={onDrop}>
                    {({getRootProps, getInputProps}:any) => (
                        <div {...getRootProps({className: 'dropzone add-file-dropzone-container'})}>
                            <input {...getInputProps()} />
                            <Button className="add-file-button" variant="contained" color="primary">Add File</Button>
                            <p>Drag and drop files here or browse them from your computer.</p>
                        </div>
                    )}
                </Dropzone>
                {
                    files.length > 0 &&
                    <div className="file-list">
                        {
                            files.map((file:any,index:number)=>(
                                <FileListItem
                                    index={index}
                                    file={file}
                                    handleDeleteFile={handleDeleteFile}
                                />
                            ))
                        }
                    </div>
                }
                <div className="add-file-upload-buttons">
                    <Button className="upload-file-button" variant="contained" onClick={handleAddFiles}  color="primary">Add File</Button>
                    <Button onClick={handleCloseModal} className="cancel-file-button" color="primary">Cancel</Button>
                </div>
            </div>
        );

        if (useWrapper === true) {
            return (
                <DialogWrapper
                    isOpen={isOpen}
                    handleCloseModal={handleCloseModal!}
                >
                    {ContentDOM}
                </DialogWrapper>
            )
        }

        return ContentDOM;
    }
}

export default AddFileDialog;
